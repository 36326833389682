/* eslint-disable */

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Grid,
  RACButton,
  RACCheckBox,
  RACDatePicker,
  RACTextbox,
  Typography,
  RACTooltip
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { ResidenceInfoProps } from "../../../interface/globalInterface";
import { formatText, handleFileChange, updateResidenceInfo } from "../Utils";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { DynamicAlertPopup } from "../DynamicAlertPopup";
import { DocumentViewer } from "../DocumentViewer";
import { MODULE_NAME, SUPPORTED_FILE_TYPE } from "../../../constants/constants";
import { ReactComponent as BlueInfoIcon } from "../../../../assets/images/blue-info-icon.svg";
import { ReactComponent as ViewEyeIcon } from '../../../assets/images/ViewPdfIcon.svg';
import { ReactComponent as PDFIcon } from "./../../../../assets/images/pdfIcon.svg";
import SecondFactor from "../../../../racstrapComponent/manageCustomer/secondAuthen";


export default function OwnResidenceInfo({
  value,
  setterData,
}: ResidenceInfoProps) {
  console.log("OwnResidenceInfoOwnResidenceInfo", value)
  console.log("setterDatasetterData", setterData)
  const classes = globalStyles();
  const Agrtransferinit = false;
  const { residenceInfo } = setterData;
  const { residenceEditClicked, residenceInfoCopy, configurations, verificationDocuments, setResidenceDoc, residenceDoc, currentRole, residenceDocumentCopy, setResidenceDocumentCopy, setIsResidenceDocDeleted } = useContext(UpdateCustomerContext)
  const fileInputRef = useRef<any>(null);
  const [messagePopup, setMessagePopup] = useState<boolean>(false);
  const [errorMessge, setErrorMessage] = useState<string>('');
  const [showDocument, setShowDocument] = useState<boolean>(false);
  const [openDocument, setOpenDocument] = useState<boolean>(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState<boolean>(false);



  useEffect(() => {
    if (residenceDocumentCopy) {
      setShowDocument(true);
    } else {
      setShowDocument(false);
    }
  }, [residenceDocumentCopy]);

  useEffect(() => {
    if (verificationDocuments && !residenceDoc) {
      console.log("VerificationDocuments", verificationDocuments);
      const resideceDocument: any = verificationDocuments?.filter(
        (doc: { refCode: any }) => doc.refCode === "RESDOC"
      );
      // Find the most recent one

      if (resideceDocument && resideceDocument?.length > 0) {
        const recentIncomeDocument: any = resideceDocument?.reduce(
          (latest: any, current: any) => {
            return new Date(latest.createdDate) > new Date(current.createdDate)
              ? latest
              : current;
          }
        );
        console.log("recentResidenceDocument", recentIncomeDocument?.file);
        if (recentIncomeDocument?.file) {
          setResidenceDoc(recentIncomeDocument?.file);
          setShowDocument(true);
        }
      }
    }
  }, [residenceDocumentCopy])


  // Function to trigger file input click event
  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFile = async (event: any) => {
    console.log('File details', event.target.files);
    if (event.target.files && event.target.files?.[0]?.size) {

      const fileSizeInBytes = event.target.files?.[0]?.size;
      let fileSize: any = fileSizeInBytes;
      let sizeUnit = "bytes";

      if (fileSizeInBytes >= 1024) {
        fileSize = fileSizeInBytes / 1024;
        sizeUnit = "KB";
      }

      if (fileSize >= 1024) {
        fileSize = fileSize / 1024;
        sizeUnit = "MB";
      }

      if (fileSize >= 1024) {
        fileSize = fileSize / 1024;
        sizeUnit = "GB";
      }

      // Round the file size to 2 decimal places
      fileSize = fileSize.toFixed(2);
      if (SUPPORTED_FILE_TYPE.includes(event.target.files[0]?.type)) {
        if (sizeUnit !== "KB") {
          if ((sizeUnit == "MB" && Number(fileSize) >= 5 && event.target.files[0]?.type == 'application/pdf')) {
            setMessagePopup(true);
            setErrorMessage("Please upload a PDF file smaller than 5MB.");
          }
          else if ((sizeUnit == "MB" && Number(fileSize) > 20) || sizeUnit == "GB") {
            setMessagePopup(true);
            setErrorMessage("Please upload a file smaller than 20MB.");
          } else {
            const base64Data: any = await handleFileChange(event);
            setShowDocument(true);
            console.log("File Base64Data", base64Data);
            let data: any = base64Data?.split(",");
            if (data?.[0]?.includes("application/pdf")) {
              data = data[1];
            } else {
              data = base64Data;
            }
            setResidenceDocumentCopy(data);
          }
        } else {
          const base64Data: any = await handleFileChange(event);
          setShowDocument(true);
          console.log("File Base64Data", base64Data);
          let data: any = base64Data?.split(",");
          if (data?.[0]?.includes("application/pdf")) {
            data = data[1];
          } else {
            data = base64Data;
          }
          setResidenceDocumentCopy(data);
        }
      } else {
        setMessagePopup(true);
        setErrorMessage("Invalid file format. Please upload a PDF, JPEG, or PNG.");
      }
    }
  }

  return (
    <Grid container>
      <Grid container className={`${classes.width100p} ${classes.mt10px}`}>

        {configurations?.EnableCustomerVerificationPhaseTwo ? <Grid id='verificationOwn' className={classes.resOwnDocumentUpload}>
          {configurations?.DocumentUploadEnable && (<Grid
            item
            md={3}
            lg={3}
            className={`${classes.pt0} ${classes.displayFlex} ${classes.flexDirectionColumn}`}
          >
            <Grid>
              <Typography component={"label"} style={{ display: 'flex' }}>
                Documents{" "}
                <Typography component={"span"} style={{ color: "red", display: 'flex' }}>
                  *
                  <RACTooltip
                    placement={"top"}
                    className={classes.tooltipcss}
                    classes={{
                      tooltip: classes.toolTipStyle4,
                      arrow: classes.arrowStyle1,
                    }}
                    title={
                      <Grid
                        className={`${classes.textCenter} ${classes.displayFlex} ${classes.justifyContentCenter} ${classes.flexDirectionColumn} ${classes.width100p}`}
                      >
                        <Typography
                          component={`label`}
                          className={`${classes.fontOpensansBold} ${classes.fontSize16px}`}
                        >
                          Acceptable Documents
                        </Typography>
                        <Typography
                          component={`label`}
                          className={`${classes.fontSize14px}`}
                        >
                          Any document that can properly associate the customer with the provided address
                        </Typography>
                        <Typography component={`label`}>
                          (e.g., Utility Bill, Mortgage Documents, Bank Statement, etc)
                        </Typography>
                      </Grid>
                    }
                  >
                    <BlueInfoIcon
                      style={{
                        height: "15px",
                        marginLeft: "0px",
                        marginTop: "3px",
                      }}
                    />
                  </RACTooltip>
                </Typography>
              </Typography>

            </Grid>
            {showDocument ? (
              <Grid className={`${classes.displayFlex}`}>
                <Typography
                  component={"label"}
                  className={residenceEditClicked ? `${classes.fontPrimaryColor} ${classes.p10px} ${classes.fontOpensansBold} ${classes.fontSize13px}` : `${classes.fontDisabledColor} ${classes.p10px} ${classes.fontOpensansBold} ${classes.fontSize13px}`}
                  style={{ minWidth: '188px' }}
                >
                  <PDFIcon style={{ float: "left", display: 'inline-block', width: 'auto', marginRight: '5px' }}></PDFIcon>

                  Proof of Residence.pdf
                </Typography>
                <Typography
                  component={"label"}
                  className={residenceEditClicked ? `${classes.fontPrimaryColor} ${classes.pointer} ${classes.p10px} ${classes.fontOpensansBold} ${classes.font16}` : `${classes.fontDisabledColor} ${classes.p10px} ${classes.fontOpensansBold} ${classes.font16}`}
                  onClick={() => {
                    if (residenceEditClicked) {
                      setShowDocument(false);
                      setResidenceDocumentCopy("");
                      setIsResidenceDocDeleted(true);
                    }
                  }}
                >
                  X
                </Typography>
                <Typography
                  onClick={() => {
                      setOpenDocument(true);
                
                  }}
                  className={`${classes.viewPdfBtnColor} ${classes.pointer} ${classes.displayFlex} ${classes.justifyContentSpaceAround} ${classes.font16} ${classes.fontOpensansBold}`}
                >
                  <span
                    style={{ cursor: 'pointer' } } onClick={() => {
                      setOpenDocument(true);
                    }}
                  >
                    <ViewEyeIcon style={{ width: '58px', height: '28px', marginLeft: '4px' }} />
                  </span>
                </Typography>
              </Grid>
            ) : (
              <RACButton
                variant="contained"
                color="primary"
                id="uploadDocument"
                disabled={
                  !residenceEditClicked
                }
                onClick={() => {
                  handleButtonClick();
                }}
                className={`${classes.resUploadImageBtn} ${classes.pointer}`}
              >
                Upload
                <input
                  ref={fileInputRef}
                  type="file"
                  onChange={handleFile}
                  accept=".png,.jpeg,.jpg,.pdf"
                  style={{ display: "none" }}
                />
              </RACButton>
            )}
          </Grid>)}
          {messagePopup ? (
            <DynamicAlertPopup
              alertType="ERROR"
              alertMessage={errorMessge}
              closeIcon={true}
              onCloseFn={() => {
                setMessagePopup(false);
              }}
            />
          ) : null}

          {openDocument ? (
            <DocumentViewer
              base64String={residenceDocumentCopy}
              onCloseFn={() => {
                setOpenDocument(false);
              }}
              popupTitle="Proof of Residence"
            />
          ) : null}

          {twoFactorAuth ? (
            <SecondFactor
              setTwoFactorCancelClick={() => {
                setTwoFactorAuth(false);
              }}
              setTwoFactorCompleted={() => {
                setOpenDocument(true);
              }}
              moduleName={MODULE_NAME.RESIDENCE_DOCUMENT_ACCESS}
              currentRole={currentRole}
            />
          ) : null}
        </Grid> :

          <Grid container md={12}>
            <Grid container md={12} spacing={2}>
              <Grid item md={2}>
                <RACCheckBox
                  id="mortgage"
                  disabled={!residenceEditClicked}
                  checked={residenceInfo.mortgage}
                  onChange={(e: any) => {
                    updateResidenceInfo(
                      setterData.residenceInfo,
                      setterData.setResidenceInfo,
                      {
                        mortgage: e.target.checked,
                        verifiedDate: '',
                        ownResidenceDetails: {
                          ...setterData.residenceInfo.ownResidenceDetails
                        }
                      }
                    );
                  }}
                  label="Mortgage"
                />
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid item md={2} className={` ${classes.mt16pxRem}`}>
                <RACDatePicker
                  value={residenceEditClicked ? value?.ownResidenceDetails.moveInDate : residenceInfoCopy?.ownResidenceDetails.moveInDate}
                  label="Move in Date"
                  disabled={!residenceEditClicked}
                  onChange={(e: any) =>
                    updateResidenceInfo(
                      setterData.residenceInfo,
                      setterData.setResidenceInfo,
                      {
                        verified: false,
                        verifiedDate: '',
                        ownResidenceDetails: {
                          ...setterData.residenceInfo.ownResidenceDetails,
                          moveInDate: e,
                        },
                      }
                    )
                  }
                />
              </Grid>
              {value.mortgage ? (
                <Grid container md={10} spacing={2}>
                  <Grid item md={2} className={`${classes.ml10px} ${classes.mt16pxRem}`}>
                    <RACTextbox
                      isCurrency={false}
                      required={true}
                      type={"text"}
                      disabled={!residenceEditClicked}
                      inputlabel="Mortgage Company Name"
                      name={`llnumberCompany`}
                      OnChange={(e: any) =>
                        updateResidenceInfo(
                          setterData.residenceInfo,
                          setterData.setResidenceInfo,
                          {
                            verified: setterData.residenceInfo.rentalType == 'RENT' ? false : setterData.residenceInfo.verified,
                            verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
                            ownResidenceDetails: {
                              ...setterData.residenceInfo.ownResidenceDetails,
                              mortgageCompanyName: formatText(e.target.value),
                            },
                          }
                        )
                      }
                      maxlength={30}
                      value={residenceEditClicked ? value.ownResidenceDetails?.mortgageCompanyName : residenceInfoCopy?.ownResidenceDetails?.mortgageCompanyName}
                      errorMessage={
                        value?.residenceInfoError?.mortgageCompanyNameError ? "Required" : ''
                      }
                    />
                  </Grid>
                  <Grid item md={2} className={`${classes.mt16pxRem}`}>
                    <RACTextbox
                      isCurrency={true}
                      type={"number"}
                      digitFormat={"currency"}
                      disabled={!residenceEditClicked}
                      inputlabel="Payment Amount"
                      OnChange={(e: any) =>
                        updateResidenceInfo(
                          setterData.residenceInfo,
                          setterData.setResidenceInfo,
                          {
                            verified: setterData.residenceInfo.rentalType == 'RENT' ? false : setterData.residenceInfo.verified,
                            verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
                            ownResidenceDetails: {
                              ...setterData.residenceInfo.ownResidenceDetails,
                              paymentAmount: e.target.value,
                            },
                          }
                        )
                      }
                      maxlength={10}
                      value={residenceEditClicked ? value.ownResidenceDetails?.paymentAmount ? value.ownResidenceDetails?.paymentAmount : '' : residenceInfoCopy?.ownResidenceDetails.paymentAmount ? residenceInfoCopy?.ownResidenceDetails?.paymentAmount : ''}
                      className={classes.currentTextBoxStyle}
                      dollarTextClassName={classes.customerDollerInputFiled}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid>
  );
}
