/* eslint-disable */
/* eslint-disable no-console */
import React, { useContext } from "react";
import imageCompression from 'browser-image-compression'

import {
  DaysPaidDropDownValue,
  EmployerDetails,
  ReferenceDetail,
  ResidenceInfo,
  UpdateCustomer,
} from "../../interface/globalInterface";
import moment from "moment";
import {
  RunDE,
  UpdateCustomerDetails,
  UpdateVerificationOptimization,
} from "./../../../api/user";
import { CustomerBasicInformation, ReferenceInformation } from "../../interface/updateCustomerInterface";
import { DOCUMENT_TYPE, EMPTY_STRING, INVALID_EMAIL_FORMAT, MAIL_REGEX, REQUIRED_ERROR, SSN } from "../../constants/constants";
import { GetCustomer, GetMenuAndStoreBasedOnProfile, getVerificationDocuments, uploadVerificationDocuments } from "../../api/user";
import jsPDF from "jspdf";
import pendingStoreActionItemClear from "../../apiHandler/updateCustomerApiHandler";

const currentDate: string = moment().format("YYYY-MM-DD");
export const updateEmploymentDetails = (
  index: number,
  employerList: EmployerDetails[],
  setterData: React.Dispatch<React.SetStateAction<EmployerDetails[]>>,
  updatedFields: Partial<EmployerDetails>
) => {
  console.log("Request Props", index, employerList, updatedFields);
  const updatedList = [...employerList];
  updatedList[index] = {
    ...updatedList[index],
    ...updatedFields,
    basicInfo: {
      ...updatedList[index].basicInfo,
      ...updatedFields.basicInfo,
    },
    incomeVerification: {
      ...updatedList[index].incomeVerification,
      ...updatedFields.incomeVerification,
    },
    addressInfo: {
      ...updatedList[index].addressInfo,
      ...updatedFields.addressInfo,
    },
  };
  setterData(updatedList); // Update the state with the modified array
};

export const formatText = (value: any): string => {
  return value && value.trim().length == 0
    ? value.trim().replace(/[^A-Z'.;’ a-z]/gi, "")
    : value.replace(/[^A-Z'.;’ a-z]/gi, "");
};
export const formatTextField = (value: any): string => {
  return value && value.trim().length == 0
    ? value.trim().replace(/[^A-Za-z.'.\s]/g, "")
    : value.replace(/[^A-Za-z.'.\s]/g, "");
};

export const formatNumber = (value: any): number => {
  return value && value.trim().length ? value.trim() : "";
};

export const formatMobileNumber = (value: string | undefined): string => {
  const phoneFormat = "($1) $2-$3";
  const cleaned = ("" + value).replace(/\D/g, "");
  //This is raw data to pass on props
  const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return cleaned.replace(PhoneRegex, phoneFormat);
};

// Function to update a single ResidenceInfo object
export const updateResidenceInfo = (
  currentData: ResidenceInfo,
  setterData: React.Dispatch<React.SetStateAction<ResidenceInfo>>,
  updatedFields: Partial<ResidenceInfo>
) => {
  console.log("Updated fields", currentData, updatedFields);
  const updatedResidenceInfo: ResidenceInfo = {
    ...currentData,
    ...updatedFields,
    rentalDetails: {
      ...currentData.rentalDetails,
      ...updatedFields.rentalDetails,
    },
    ownResidenceDetails: {
      ...currentData.ownResidenceDetails,
      ...updatedFields.ownResidenceDetails,
    },
  };
  setterData(updatedResidenceInfo);
};

export const updateObjectStateVariable = <T,>(
  setState: React.Dispatch<React.SetStateAction<T>>,
  key: string,
  value: any
) => {
  setState((prevState) => ({
    ...prevState,
    [key]: value,
  }));
};

export const updateReferenceInfo = (
  index: number | undefined,
  referenceInfo: ReferenceInformation[],
  setterData: React.Dispatch<React.SetStateAction<ReferenceInformation[]>>,
  updatedFields: Partial<ReferenceInformation>
) => {
  console.log("Request Props", index, referenceInfo, updatedFields);
  index = index ? index : 0;
  const updatedList = [...referenceInfo];
  updatedList[index] = {
    ...updatedList[index],
    ...updatedFields,
    referenceInfo: {
      ...updatedList[index].referenceInfo,
      ...updatedFields.referenceInfo,
    },
    referenceAddressInfo: {
      ...updatedList[index].referenceAddressInfo,
      ...updatedFields.referenceAddressInfo,
    },
  };
  setterData(updatedList);
};

export const formatZipCode = (value: string | undefined) => {
  const cleaned = ("" + value).replace(/\D/g, "");
  return cleaned.length > 5
    ? cleaned.slice(0, 5) + "-" + cleaned.slice(5)
    : cleaned;
};

// Generic function to update array of objects
export const updateArrayOfObjectState = (
  stateVariable: any,
  setterFunction: any,
  index: any,
  updateFields: any
) => {
  console.log('Props in UpdateArray', stateVariable, updateFields);
  const updatedState = [...stateVariable];
  updatedState[index] = {
    ...updatedState[index],
    ...updateFields,
  };
  setterFunction(updatedState);
};

//Null Variable Checker
export const isNotNullVariable = (
  value: string | null | undefined | number
): boolean => {
  return value && value !== "null" && value !== "undefined" ? true : false;
};

export const isNonEmptyArray = <T,>(value: T[]): boolean => {
  return Array.isArray(value) && value.length > 0;
};

export const isDropDownValueExist = (
  options: { value?: string }[],
  value: string
): boolean => {
  return options.some((option) => option.value === value);
};

export const validateEmploymentInfo = (data: EmployerDetails) => {
  console.log("data", data);
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  return (
    !isNotNullVariable(data.basicInfo.companyName) ||
    !isNotNullVariable(data.basicInfo.phoneNumber) ||
    data.basicInfo.phoneNumber.length < 10 ||
    !isNotNullVariable(data.incomeVerification.paydayFrequency) ||
    data.incomeVerification.paydayFrequency === "0" ||
    (data.incomeVerification.paydayFrequency &&
      data.incomeVerification.paydayFrequency !== "0" &&
      data.incomeVerification.paydayFrequency !== "DAILY" &&
      !(
        data.incomeVerification.dayPaid &&
        data.incomeVerification.dayPaid !== "0"
      ))
  );
};

export const getDaysPaidDropDown = (
  paydayFrequency: string,
  dropdownOptions: DaysPaidDropDownValue
) => {
  console.log('dropdownOptions', dropdownOptions);
  switch (paydayFrequency) {
    case "SEMI":
      return dropdownOptions.semiMonthly;
    case "WK":
      return dropdownOptions.weekly;
    case "BIWK":
      return dropdownOptions.weekly;
    case "MON":
      return dropdownOptions.monthly;
    default:
      return [];
  }
};

export const removeObjectFromArray = <T,>(
  datas: T[],
  index: number,
  setterFunction: React.Dispatch<React.SetStateAction<T[]>>
) => {
  setterFunction([...datas.slice(0, index), ...datas.slice(index + 1)]);
};

export const verifiedResidenceDisable = (
  residences: ResidenceInfo
): boolean => {
  const { rentalType, ownResidenceDetails, rentalDetails } = residences;

  if (rentalType === "RENT") {
    return !(
      isNotNullVariable(rentalDetails.landlordsFirstName) &&
      isNotNullVariable(rentalDetails.landlordsLastName) &&
      isNotNullVariable(rentalDetails.landlordsPhoneNumber) &&
      rentalDetails.landlordsPhoneNumber.length >= 10
    );
  } else if (rentalType === "OWN" && residences.mortgage) {
    return !isNotNullVariable(ownResidenceDetails.mortgageCompanyName);
  } else {
    return true;
  }
};

// export const verifiedRefernceDisable = (data: Partial<ReferenceInformation>): boolean => {
//   const { relativeFirstName, relativeLastName, phoneNumber, relationShip } =
//     data.referenceInfo;

//   if (!isNotNullVariable(relativeFirstName)) return true;
//   if (!isNotNullVariable(relativeLastName)) return true;
//   if (!isNotNullVariable(phoneNumber) || phoneNumber.length < 10) return true;
//   if (!isNotNullVariable(relationShip) || relationShip === "0") return true;

//   return false;
// };

export const updateCustomerAndVerification = (
  updateObject: any,
  verifiedTab: string,
  customerId: string,
  setGetCustomerInfoResponse: any,
  setGetApprovalRes: any,
  actualDocumentDetail?: any,
  setIntellicheckStatus?: any,
  uploadDocDetails?: any,
  EnableCustomerVerificationPhaseTwo?: boolean,
  history?: any
): Promise<boolean> => {
  let updateCustomerPaylaod: any = {};
  let updateApprovalPayload: any = {};
  console.log("Udpated Customer Payload", uploadDocDetails);
  console.log("updateObjectupdateObject", updateObject)

  console.log("EnableCustomerVerificationPhaseTwo", EnableCustomerVerificationPhaseTwo)
  const verified = updateObject && updateObject?.verified ? "Y" : "N";
  const verifiedDate =
    updateObject && updateObject.verified ? currentDate : undefined;
  let uploadDocPayload: any = '';

  switch (verifiedTab) {
    case "ID":
      updateCustomerPaylaod = {
        customerId: customerId,
        governmentIdType: updateObject.governmentIdType,
        governmentId: updateObject?.governmentId?.includes('*') ? updateObject?.govtIdDecrpted?.replaceAll('*', '') : updateObject.governmentId,
        governmentIdIssuedState: updateObject.issuedState,
      };
      updateApprovalPayload = updateObject?.decisionEngineId ? {
        decisionId: updateObject.decisionEngineId,
        isManageApproval: true,
        verificationDocuments: [
          {
            clientId: "RACPad",
            verificationStatus: verified == 'Y' ? 'VERIFIED' : EnableCustomerVerificationPhaseTwo ? 'PENDREW' : 'PEND',
            verifyType: "ID",
            modifiedBy: updateObject?.modifiedBy
          },
        ],
      } : {};

      updateApprovalPayload?.verificationDocuments?.[0]?.verified == true ? setIntellicheckStatus('Passed') : setIntellicheckStatus('');
      break;
    case "EMP":
      updateApprovalPayload = updateObject[0].decisionEngineId ? {
        decisionId: updateObject[0].decisionEngineId,
        isManageApproval: true,
        verificationDocuments: [
          {
            verifyType: "EMPLOYMENT",
            clientId: "RACPad",
            modifiedBy: updateObject[0]?.modifiedBy,
            verificationStatus: updateObject?.[0]?.verified ? 'VERIFIED' : (uploadDocDetails?.action == 5 || !EnableCustomerVerificationPhaseTwo) ? 'PEND' : 'PENDREW'
          },
        ],
      } : {};
      uploadDocPayload = uploadDocDetails
      delete updateObject[0]?.decisionEngineId;
      delete updateObject[0]?.verifyCode;
      delete updateObject[0]?.override;
      delete updateObject[0]?.verified;
      delete updateObject[0]?.modifiedBy;
      updateCustomerPaylaod = updateObject?.[0]?.employerPayschedule == '0' ? null : {
        customerId: customerId,
        employerReferences: updateObject,
      };
      break;
    case "RESI":
      if (EnableCustomerVerificationPhaseTwo) {
        if (updateObject.rentalType == 'RENT') {
          updateCustomerPaylaod = updateObject?.rentalDetails?.landlordOrAptFirstName ?
            {
              customerId: customerId,
              landlordReferences: [{
                landlordFirstName: updateObject?.rentalDetails?.landlordOrAptFirstName ? updateObject?.rentalDetails?.landlordOrAptFirstName
                  : null,
                landlordLastName: null,
                phoneNumber: updateObject?.rentalDetails?.landlordOrAptPhoneNumber?.replace(/[()\s-]/g, '') ? updateObject?.rentalDetails?.landlordOrAptPhoneNumber?.replace(/[()\s-]/g, '') : '',
                monthlyRent: '0.00',
                moveInDate: null,
                active: 'Y',
                leaseLengthYears: "0.000000",
                leaseLengthMonths: "0.000000",
                leaseTerm: null,
                leaseCompanyName: null,
                phoneExtension: null,
                addressLine1: null,
                addressLine2: null,
                city: null,
                state: null,
                postalCode: "",
                leaseHolderName: null,
                bestTimeToCall: null,
                verifiedDate: "",
                residenceTenureType: updateObject?.rentalDetails?.residenceTenureType ? updateObject?.rentalDetails?.residenceTenureType : null,
                landlordReferenceId: updateObject?.landlordReferenceId ? updateObject?.landlordReferenceId : ''
              }],
              residenceType: 'RENT'
            } : {
              customerId: customerId,
              residenceType: 'RENT'
            }
        }
        else {
          updateCustomerPaylaod = {
            customerId: customerId,
            residenceType: 'OWN'
          }
        }
      } else {
        if (updateObject.rentalType == 'RENT') {
          updateCustomerPaylaod = {
            customerId: customerId,
            landlordReferences: [{
              landlordFirstName: updateObject?.rentalDetails?.landlordsFirstName ? updateObject?.rentalDetails?.landlordsFirstName
                : null,
              landlordLastName: updateObject?.rentalDetails?.landlordsLastName ? updateObject?.rentalDetails?.landlordsLastName
                : null,
              phoneNumber: updateObject?.rentalDetails?.landlordsPhoneNumber?.replace(/[()\s-]/g, '')
                ? updateObject?.rentalDetails?.landlordsPhoneNumber?.replace(/[()\s-]/g, '') : '',
              monthlyRent:
                updateObject?.rentalDetails?.paymentAmount ? updateObject?.rentalDetails?.paymentAmount.replace(',', '').toString()
                  : '0.00',
              moveInDate:
                updateObject?.rentalDetails?.moveInDate ? updateObject?.rentalDetails?.moveInDate
                  : null,
              active: 'Y',
              leaseLengthYears: "0.000000",
              leaseLengthMonths: "0.000000",
              leaseTerm: null,
              leaseCompanyName: null,
              phoneExtension: null,
              addressLine1: null,
              addressLine2: null,
              city: null,
              state: null,
              postalCode: "",
              leaseHolderName: null,
              bestTimeToCall: null,
              verifiedDate: "",
              residenceTenureType: updateObject?.rentalDetails?.residenceTenureType ? updateObject?.rentalDetails?.residenceTenureType : null,
              landlordReferenceId: updateObject?.landlordReferenceId ? updateObject?.landlordReferenceId : ''
            }]
          }
        }

        else {
          updateCustomerPaylaod = {
            customerId: customerId,

            mortgageCompanyName: updateObject?.mortgage && updateObject?.ownResidenceDetails?.mortgageCompanyName ? updateObject?.ownResidenceDetails?.mortgageCompanyName : null,
            monthlyMortgagePayment: updateObject?.mortgage &&
              updateObject?.ownResidenceDetails?.paymentAmount
              ? updateObject?.ownResidenceDetails?.paymentAmount.replace(',', '').toString()
              : null,
            residenceSince: updateObject?.ownResidenceDetails?.moveInDate
              ? updateObject?.ownResidenceDetails?.moveInDate
              : null,
            residenceType: 'OWN'
          }
        }
      }
      updateObject?.decisionId ?
        updateApprovalPayload = {
          decisionId: updateObject?.decisionId ? updateObject?.decisionId : '',
          isManageApproval: true,
          verificationDocuments: [
            {
              verifyType: "RESIDENCE",
              clientId: "RACPad",
              modifiedBy: updateObject?.modifiedBy,
              verificationStatus: updateObject?.residenceVerificationToggle ? 'VERIFIED' : (uploadDocDetails?.action == 5 || !EnableCustomerVerificationPhaseTwo) ? 'PEND' : 'PENDREW'
            },
          ],
        } : updateApprovalPayload = {};
      uploadDocPayload = uploadDocDetails
      break;
    case "REF":
      updateApprovalPayload = updateObject[0]?.decisionId ? {
        decisionId: updateObject[0]?.decisionId ? updateObject[0]?.decisionId : '',
        isManageApproval: true,
        verificationDocuments: [
          {
            clientId: "RACPad",
            verifyType: "REFERENCES",
            modifiedBy: updateObject[0]?.modifiedBy,
            verificationStatus: updateObject[0].verified ? 'VERIFIED' : EnableCustomerVerificationPhaseTwo && updateObject[0].isRequiredDetailsProvided ? 'PENDREW' : 'PEND'
          }
        ],
      } : updateApprovalPayload = {};
      delete updateObject[0]?.decisionId;
      delete updateObject[0]?.verifyCode;
      delete updateObject[0]?.modifiedBy;
      delete updateObject[0]?.verified;
      delete updateObject[0]?.isRequiredDetailsProvided;
      updateCustomerPaylaod = {
        customerId: customerId,
        personalReferences: updateObject
      };
      break;
    default:
      break;
  }

  return updateCustomerDetails(updateCustomerPaylaod, updateApprovalPayload, setGetCustomerInfoResponse, setGetApprovalRes, uploadDocPayload, customerId, history);
};



export const updateCustomerDetails = async (
  updateCustomerPaylaod: Partial<UpdateCustomer>,
  updateApprovalPayload: any,
  setGetCustomerInfoResponse: any,
  setGetApprovalRes: any,
  uploadDocPayload: any,
  customerId: any,
  history?: any

): Promise<any> => {
  try {
    console.log('Inside the Update Customer Payload', updateApprovalPayload, uploadDocPayload);
    if (updateApprovalPayload && updateApprovalPayload?.decisionId) {
      const manageApprovalResp = await UpdateVerificationOptimization(
        updateApprovalPayload
      );
      console.log('manageApprovalResp', manageApprovalResp);
      if (manageApprovalResp) {
        // const getApprovalResponse = await GetApproval()
        console.log('manageApprovalResp', manageApprovalResp.decisionId)
      }
    }
    const [updateCustomer, updateDocument] = await Promise.all([
      updateCustomerPaylaod ? UpdateCustomerDetails(updateCustomerPaylaod) : Promise.resolve(true),
      uploadDocPayload ? uploadVerificationDocuments(uploadDocPayload) : Promise.resolve(true) // Skip if payload is not provided
    ]);

    console.log('Upload Document Response', updateDocument);
    if (setGetCustomerInfoResponse && setGetApprovalRes && updateApprovalPayload && updateApprovalPayload?.decisionId) {
      const getCustomerResponse = await GetCustomer(updateCustomerPaylaod?.customerId || customerId, window.sessionStorage.getItem('storeNumber') || '');
      console.log('Inside the SetGetCustomerInfo', getCustomerResponse);
      setGetCustomerInfoResponse(getCustomerResponse?.data);
      setGetApprovalRes(getCustomerResponse?.data?.GetApproval?.value?.customerApprovalDetails);
      pendingStoreActionItemClear(getCustomerResponse?.data?.GetApproval?.value?.customerApprovalDetails, history, updateCustomerPaylaod?.customerId || customerId)
    }
    console.log("updateCustomer", updateCustomer);
    return updateDocument;
  } catch (error: any) {
    console.log("Error In Updating Customer", error);
    return false;
  }
};

// For formatting the text
export const formatTextChange = (value: any) => {
  return value && value.trim().length > 0 ? value.trim() : "";
};

/**
 * This function formates the date from MM/DD/YYYY to YYYY-MM-DD
 */
export const formatDateOfBirth = (date: string) => {
  console.log('fomateDateofBirth', date);
  const formatdate = moment(date).format('MM/DD/YYYY');
  const [month, day, year] = formatdate.split("/");
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

export const formBasicInfoPayload = (
  basicInfo: CustomerBasicInformation,
  customerId: string
) => {
  return {
    customerId: customerId,
    firstName: basicInfo.firstName ? basicInfo.firstName : undefined,
    lastName: basicInfo.lastName ? basicInfo.lastName : undefined,
    middleInitial: basicInfo.middleInitial
      ? basicInfo.middleInitial
      : undefined,
    coworker: basicInfo.racCoworker ? basicInfo.racCoworker : undefined,
    suffix: basicInfo.suffix ? basicInfo.suffix : undefined,
    salutation: basicInfo.salutation ? basicInfo.salutation : undefined,
    dateOfBirth: basicInfo.dateOfBirth
      ? formatDateOfBirth(basicInfo.dateOfBirth)
      : undefined,
    taxId: basicInfo.ssn ? basicInfo.ssn : undefined,
    taxIdType: basicInfo.ssn ? SSN : undefined,
  };
};

export const validateEmail = (emailId: string, errorSetterFunc: React.Dispatch<React.SetStateAction<string>>): void => {
  try {
    if (emailId.trim() === EMPTY_STRING) {
      errorSetterFunc(REQUIRED_ERROR);
    } else if (!MAIL_REGEX.test(emailId)) {
      errorSetterFunc(INVALID_EMAIL_FORMAT);
    } else {
      errorSetterFunc(EMPTY_STRING);
    }
  } catch (error: any) {
    console.log("Error in validateEmail: ", error?.message);
  }
};

export const updateObjectWithMultipleKeys = <T,>(setStateFunction: React.Dispatch<React.SetStateAction<T>>, updates: Partial<Record<keyof T, any>>) => {
  setStateFunction(prevState => ({
    ...prevState,
    ...updates
  }));
};

export const getCoworkerNames = async (
  setterFunc: any,
  coWorkerId: any,
  setCurrentRole: any,
  GetApprovalResp: any
) => {
  try {
    console.log("GetApprovalRespUtils", GetApprovalResp);
    const approvalIds: string[] = coWorkerId ? [coWorkerId] : [];
    GetApprovalResp?.verificationDocuments?.forEach((element: any) => {
      element?.lastModifiedBy && !approvalIds.includes(element.lastModifiedBy)
        ? approvalIds.push(element.lastModifiedBy)
        : null;
    });

    const getCoworkerNamesFunc = approvalIds.map((element: any) => {
      if (element != 'Intellicheck' && element != 'DOF' && element != 'EC' && element != 'ECOM' && element != 'SF') {
        return GetMenuAndStoreBasedOnProfile({
          coworkerId: element,
          storesRequired: false,
          menuRequired: false,
        })
      }
    }

    );

    console.log("getCoworkerNamesFunc", getCoworkerNamesFunc);

    const coworkerRes = await Promise.all(getCoworkerNamesFunc);

    console.log("coworkerRes", coworkerRes);
    const coWorkerName: any = {
      'Intellicheck': 'Intellicheck',
      'DOF': 'DOF',
      'ECOM': 'ECOM',
      'SF': 'Sales Force',
      'EC': 'ECOM'
    };
    coworkerRes.forEach((element: any) => {
      if (
        element?.status === 200 &&
        element?.data?.coworkerProfile?.firstName &&
        element?.data?.coworkerProfile?.lastName
      ) {
        const { employeeId, firstName, lastName, role } =
          element.data.coworkerProfile;
        coWorkerName[employeeId] = `${firstName} ${lastName}`;
        if (employeeId == coWorkerId) {
          setCurrentRole(role);
        }
      } else {
        console.log("Error in coworkerRes Api:", element);
      }
    });
    console.log("coWorkerNames", coWorkerName);
    setterFunc(coWorkerName);
  } catch (error: any) {
    console.log("Error in getCoworkerNames function:", error);
  }
};

// Function to handle file selection and conversion to Base64
export const handleFileChange = async (event: any) => {
  const selectedFile = event.target.files[0];
  if (!selectedFile) return;

  const fileType = selectedFile.type;

  // If the file is already a PDF, convert it directly to Base64
  if (fileType === 'application/pdf') {
    const base64PDF = await convertPDFToBase64(selectedFile);
    return base64PDF;
  } else {
    // If the file is not a PDF, convert it to PDF first
    const options = {
      maxSizeMB: 2, // Compress to below 2MB,
    };
    const compressesFile: any = await imageCompression(selectedFile, options);
    const pdfBase64 = await convertFileToPDFAndBase64(compressesFile);
    return pdfBase64;
  }
};

// Convert PDF file to Base64
const convertPDFToBase64 = async (file: any) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      const base64String = arrayBufferToBase64(arrayBuffer);
      resolve(base64String);
    };
    reader.onerror = reject;
  });
};

// Helper function to convert ArrayBuffer to Base64
const arrayBufferToBase64 = (buffer: any) => {
  const byteArray = Array.from(new Uint8Array(buffer));
  let binary = '';

  for (let i = 0; i < byteArray.length; i++) {
    binary += String.fromCharCode(byteArray[i]);
  }

  return btoa(binary);
};

// Convert non-PDF file (image or text) to PDF and then to Base64
const convertFileToPDFAndBase64 = async (file: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve, reject) => {
    reader.onloadend = async () => {
      const dataURL: any = reader.result;
      const pdf = new jsPDF();

      // If the file is an image, add it to the PDF
      if (file.type.startsWith('image')) {
        pdf.addImage(dataURL, 'JPEG', 10, 10, 180, 160);
      } else {
        // If it's not an image, you could write text to the PDF (or other content)
        pdf.text("File Content", 20, 30);
      }

      // Convert the generated PDF to Base64
      const pdfBase64 = pdf.output('datauristring');
      resolve(pdfBase64);
    };
    reader.onerror = reject;
  });
};

export function cleanBase64String(base64String: string) {
  return base64String.replace(/^data:application\/pdf;base64,/, '');
}

export const getCustomerVerificationDocuments = async (decisionEngineId: string, setVerificationDocuments: any) => {
  const getDocumentsPayload = (documentType: any) => ({
    identifier: decisionEngineId,
    documentStatus: 'signed',
    actionType: 'print',
    identifierType: 'CUST',
    customerVerificationDocument: 'true',
    documentType
  });

  // const getIdentityDocumentsPayload = getDocumentsPayload(DOCUMENT_TYPE.IDENTITY_DOC);
  const getIncomeDocPayload = getDocumentsPayload(DOCUMENT_TYPE.EMPLOYMENT_DOC);
  const getResidenceDocPayload = getDocumentsPayload(DOCUMENT_TYPE.RESIDENCE_DOC);

  // Execute all three API calls in parallel using Promise.all
  const [incomeDoc, residenceDoc] = await Promise.all([
    getVerificationDocuments(getIncomeDocPayload),
    getVerificationDocuments(getResidenceDocPayload)
  ]);

  console.log('verificationDocumentsRespo', incomeDoc, residenceDoc);
  const verificationDocument: any = [];
  // if (identityDoc?.status == 200 && identityDoc?.data?.documents?.length) {
  //   identityDoc?.data?.documents?.forEach((ele: any) => {
  //     verificationDocument.push(ele);
  //   })
  // }
  if (incomeDoc?.status == 200 && incomeDoc?.data?.documents?.length) {
    incomeDoc?.data?.documents?.forEach((ele: any) => {
      verificationDocument.push(ele);
    })
  }
  if (residenceDoc?.status == 200 && residenceDoc?.data?.documents?.length) {
    residenceDoc?.data?.documents?.forEach((ele: any) => {
      verificationDocument.push(ele);
    })
  }
  console.log('Verification Documents Formed', verificationDocument);
  setVerificationDocuments(verificationDocument);
}


export const updateIntellicheckDetails = async (response: any, modifiedBy: any, passLimit: number) => {
  const verificationStatus =  response?.id_score >= passLimit ? 'VERIFIED' : 'PENDREW'
  const updateIntellicheckReq: any = {
    approvalVerifications: [
      {
        verificationStatus: verificationStatus,
        verificationEvidence: {
          lastName: response?.last_name,
          locationScore: response?.location_score
            ? response?.location_score?.toString()
            : "0",
          idScore: response?.id_score ? response?.id_score?.toString() : "0",
          address1: response?.address1 || "",
          provinceCode: response?.province_code || "",
          postalCode: response?.postal_code || "",
          idDocument: {
            number: response?.id_document?.number || "",
            provinceCode: response?.id_document?.province_code || "",
            type: response?.id_document?.type || "",
          },
          locality: response?.locality,
          matches: {
            firstName: response?.matches?.first_name ? true : false,
            lastName: response?.matches?.last_name ? true : false,
            dob: response?.matches?.dob ? true : false,
          },
          firstName: response?.first_name || "",
          dob: response?.dob,
          countryCode: response?.country_code,
          nameScore: response?.name_score ? response?.name_score?.toString() : "0",
        },
        modifiedBy: modifiedBy, // This would typically be dynamic, replace if needed
        verifyType: "ID",
      },
    ],
  };
  return updateIntellicheckReq.approvalVerifications;
}
