/* eslint-disable */
import accordianOpenIcon from '../../assets/images/Accordion-close.svg';
import accordianCloseIcon from '../../assets/images/Accordion-close.svg';
import { makeStyles, RACCOLOR } from "@rentacenter/racstrap";

export const updateCustomerStyles = () => {
    const UseClasses = makeStyles((theme) => ({
        mr4: {
            marginRight: theme.typography.pxToRem(4)
        },
        mr8: {
            marginRight: theme.typography.pxToRem(8)
        },

        mt24: {
            marginTop: theme.typography.pxToRem(24)
        },
        mb10: { marginBottom: theme.typography.pxToRem(10) },

        mb8: {
            marginBottom: theme.typography.pxToRem(8),
        },
        cardStyle: {
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
        },
        textAlignCenter: {
            textAlign: 'center'
        },
        justifyContentCenter: {
            justifyContent: 'center'
        },
        
        alertThickFont: {
            fontFamily: 'OpenSans-bold',
            fontSize: theme.typography.pxToRem(16),
            letterSpacing: 0,
        },

        clubDetailsContainer: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        clubFooter: { display: 'flex', justifyContent: 'end', marginTop: '20px' },
        title: {
            color: '#212529',
            fontFamily: 'OpenSans-bold',
            fontSize: '16px',
            marginBottom: '10px',
        },

        fixedFooter: {
            position: 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 0,
            backgroundColor: `${RACCOLOR.WHITE}`,
            boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
            padding: '1rem',
        },
        racTabStyle: {

            '& .Mui-selected': {
                '& .RAC-CUSTOMER--MuiTab-wrapper': {
                    color: '#0d6efd'
                },
            },

            '& div': {

                '& .RAC-CUSTOMER--MuiTabs-flexContainer': {
                    backgroundColor: 'white'
                },

            },
            '& .RAC-CUSTOMER--MuiTab-wrapper': {
                color: 'black'
            },
            '& #full-width-tabpanel-0': {
                width: '100%',
                padding: '10px'
            },
            '& #full-width-tabpanel-1': {
                width: '100%',
                padding: '10px'
            },
            '& #full-width-tabpanel-2': {
                width: '100%',
                padding: '10px'
            },
            '& #full-width-tabpanel-3': {
                width: '100%',
                padding: '10px'
            },
            '& #full-width-tabpanel-4': {
                width: '100%',
                padding: '10px'
            },
            '& #full-width-tabpanel-5': {
                width: '100%',
                padding: '10px'
            },
            '& #full-width-tabpanel-6': {
                width: '100%',
                padding: '10px'
            },

        },
        breadcrumbArrow: {
            '&:before': {
                content: '""',
                width: 0,
                height: 0,
                borderTop: '5px solid transparent',
                borderLeft: '7px solid #707070',
                borderBottom: '5px solid transparent',
                marginTop: 0,
                float: 'left',
                paddingRight: '13px',
                color: '#6c757d',
                paddingLeft: 0,
            },
        },
        card: {
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
        },
        justifyEnd: {
            justifyContent: 'end'
        },

        racBlue: {
            color: RACCOLOR.NORMAL_BLUE
        },
        mb10: { marginBottom: theme.typography.pxToRem(10) },
        mb8: {
            marginBottom: theme.typography.pxToRem(8),
        },
        mb16: {
            marginBottom: theme.typography.pxToRem(16),
        },
        mt16: {
            marginTop: theme.typography.pxToRem(16)
        },
        mr8: {
            marginRight: theme.typography.pxToRem(8),
        },
        mr16: {
            marginRight: theme.typography.pxToRem(16),
        },
        mt8: {
            marginTop: theme.typography.pxToRem(8)
        },
        prl8: {
            paddingRight: theme.typography.pxToRem(8),
            paddingLeft: theme.typography.pxToRem(8),
        },
        toggleGrid: {
            display: 'inline-flex',
            flexDirection: 'row-reverse',
        },

        toggleLabelGrid: {
            marginLeft: theme.typography.pxToRem(10),
            marginRight: theme.typography.pxToRem(10)
        },

        toggleLabel: {
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
        },

        customerToolbar: {
            position: 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1030,
            backgroundColor: `${RACCOLOR.WHITE}`,
            boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
            paddingLeft: theme.typography.pxToRem(16),
            paddingRight: theme.typography.pxToRem(16),
            paddingTop: theme.typography.pxToRem(16),
            paddingBottom: theme.typography.pxToRem(16),
        },
        title: {
            color: '#212529',
            fontFamily: 'OpenSans-bold',
            fontSize: '16px',
            marginBottom: '10px',
        },
        p6: {
            padding: "6px !important",
        },
        dropdowntoggle: {
            display: 'inline-block',
            marginLeft: '0.255em',
            verticalAlign: '0.255em',
            content: '',
            borderTop: '0.3em solid',
            borderRight: '0.3em solid transparent',
            borderBottom: '0',
            borderLeft: '0.3em solid transparent',
        },
        /* Margin and Padding spacing */
        m0: {
            margin: 0,
        },

        m1: {
            margin: theme.typography.pxToRem(4),
        },
        pointerEvent: {
            pointerEvents: 'none',
            opacity: '0.6',
        },
        m2: {
            margin: theme.typography.pxToRem(8),
        },

        m3: {
            margin: theme.typography.pxToRem(16),
        },

        m4: {
            margin: theme.typography.pxToRem(24),
        },

        m5: {
            margin: theme.typography.pxToRem(48),
        },

        mAuto: {
            margin: 'auto',
        },

        mx0: {
            marginRight: 0,
            marginLeft: 0,
        },

        mx1: {
            marginRight: theme.typography.pxToRem(4),
            marginLeft: theme.typography.pxToRem(4),
        },
        mx2: {
            marginRight: theme.typography.pxToRem(8),
            marginLeft: theme.typography.pxToRem(8),
        },

        mx3: {
            marginRight: theme.typography.pxToRem(16),
            marginLeft: theme.typography.pxToRem(16),
        },
        mx4: {
            marginRight: theme.typography.pxToRem(24),
            marginLeft: theme.typography.pxToRem(24),
        },

        mx5: {
            marginRight: theme.typography.pxToRem(48),
            marginLeft: theme.typography.pxToRem(48),
        },

        mxAuto: {
            marginRight: 'auto',
            marginLeft: 'auto',
        },

        my0: {
            marginTop: 0,
            marginBottom: 0,
        },

        my1: {
            marginTop: theme.typography.pxToRem(4),
            marginBottom: theme.typography.pxToRem(4),
        },

        my2: {
            marginTop: theme.typography.pxToRem(8),
            marginBottom: theme.typography.pxToRem(8),
        },

        my3: {
            marginTop: theme.typography.pxToRem(16),
            marginBottom: theme.typography.pxToRem(16),
        },

        my4: {
            marginTop: theme.typography.pxToRem(24),
            marginBottom: theme.typography.pxToRem(24),
        },

        my5: {
            marginTop: theme.typography.pxToRem(48),
            marginBottom: theme.typography.pxToRem(48),
        },

        myAuto: {
            marginTop: 'auto',
            marginBottom: 'auto',
        },

        mt0: {
            marginTop: 0,
        },
        mt2: {
            marginTop: theme.typography.pxToRem(8),
        },

        mt3: {
            marginTop: theme.typography.pxToRem(16),
        },
        mtAuto: {
            marginTop: 'auto',
        },
        me0: {
            marginRight: 0,
        },

        me1: {
            marginRight: theme.typography.pxToRem(4),
        },

        me3: {
            marginRight: theme.typography.pxToRem(16),
        },

        me4: {
            marginRight: theme.typography.pxToRem(24),
        },

        me5: {
            marginRight: theme.typography.pxToRem(48),
        },

        meAuto: {
            marginRight: 'auto',
        },

        mb0: {
            marginBottom: 0,
        },

        mb1: {
            marginBottom: theme.typography.pxToRem(4),
        },
        mb4: {
            marginBottom: theme.typography.pxToRem(24),
        },
        mb5: {
            marginBottom: theme.typography.pxToRem(48),
        },

        mbAuto: {
            marginBottom: 'auto',
        },

        ms0: {
            marginLeft: 0,
        },

        ms1: {
            marginLeft: theme.typography.pxToRem(4),
        },
        ms3: {
            marginLeft: theme.typography.pxToRem(16),
        },

        ms4: {
            marginLeft: theme.typography.pxToRem(24),
        },

        ms5: {
            marginLeft: theme.typography.pxToRem(48),
        },

        msAuto: {
            marginLeft: 'auto',
        },

        p0: {
            padding: 0,
        },

        p1: {
            padding: theme.typography.pxToRem(4),
        },

        p2: {
            padding: theme.typography.pxToRem(8),
        },

        p3: {
            padding: theme.typography.pxToRem(16),
        },

        p4: {
            padding: theme.typography.pxToRem(24),
        },

        p5: {
            padding: theme.typography.pxToRem(48),
        },

        px0: {
            paddingRight: 0,
            paddingLeft: 0,
        },

        px1: {
            paddingRight: theme.typography.pxToRem(4),
            paddingLeft: theme.typography.pxToRem(4),
        },
        px3: {
            paddingRight: theme.typography.pxToRem(16),
            paddingLeft: theme.typography.pxToRem(16),
        },

        px4: {
            paddingRight: theme.typography.pxToRem(24),
            paddingLeft: theme.typography.pxToRem(24),
        },

        px5: {
            paddingRight: theme.typography.pxToRem(48),
            paddingLeft: theme.typography.pxToRem(48),
        },

        py0: {
            paddingTop: 0,
            paddingBottom: 0,
        },

        py1: {
            paddingTop: theme.typography.pxToRem(4),
            paddingBottom: theme.typography.pxToRem(4),
        },

        py2: {
            paddingTop: theme.typography.pxToRem(8),
            paddingBottom: theme.typography.pxToRem(8),
        },

        py3: {
            paddingTop: theme.typography.pxToRem(16),
            paddingBottom: theme.typography.pxToRem(16),
        },

        py4: {
            paddingTop: theme.typography.pxToRem(24),
            paddingBottom: theme.typography.pxToRem(24),
        },

        py5: {
            paddingTop: theme.typography.pxToRem(48),
            paddingBottom: theme.typography.pxToRem(48),
        },

        pt0: {
            paddingTop: 0,
        },

        pt1: {
            paddingTop: theme.typography.pxToRem(4),
        },

        pt2: {
            paddingTop: theme.typography.pxToRem(8),
        },

        pt3: {
            paddingTop: theme.typography.pxToRem(16),
        },

        pt4: {
            paddingTop: theme.typography.pxToRem(24),
        },

        pt5: {
            paddingTop: theme.typography.pxToRem(48),
        },

        pe0: {
            paddingRight: 0,
        },

        pe1: {
            paddingRight: theme.typography.pxToRem(4),
        },

        pe2: {
            paddingRight: theme.typography.pxToRem(8),
        },

        pe3: {
            paddingRight: theme.typography.pxToRem(16),
        },

        pe4: {
            paddingRight: theme.typography.pxToRem(24),
        },

        pe5: {
            paddingRight: theme.typography.pxToRem(48),
        },

        pb0: {
            paddingBottom: 0,
        },

        pb1: {
            paddingBottom: theme.typography.pxToRem(4),
        },

        pb2: {
            paddingBottom: theme.typography.pxToRem(8),
        },

        pb3: {
            paddingBottom: theme.typography.pxToRem(16),
        },

        pb4: {
            paddingBottom: theme.typography.pxToRem(24),
        },

        pb5: {
            paddingBottom: theme.typography.pxToRem(48),
        },

        ps0: {
            paddingLeft: 0,
        },

        ps1: {
            paddingLeft: theme.typography.pxToRem(4),
        },
        ps3: {
            paddingLeft: theme.typography.pxToRem(16),
        },

        ps4: {
            paddingLeft: theme.typography.pxToRem(24),
        },

        ps5: {
            paddingLeft: theme.typography.pxToRem(48),
        },

        font11: {
            fontSize: theme.typography.pxToRem(11),
        },

        font12: {
            fontSize: theme.typography.pxToRem(12),
        },

        font13: {
            fontSize: theme.typography.pxToRem(13),
        },

        font14: {
            fontSize: theme.typography.pxToRem(14),
        },

        font15: {
            fontSize: theme.typography.pxToRem(15),
        },

        font17: {
            fontSize: theme.typography.pxToRem(17),
        },

        font18: {
            fontSize: theme.typography.pxToRem(18),
        },
        textRight: {
            textAlign: 'right',
        },
        textCenter: {
            textAlign: 'center',
        },
        headerTab: {
            paddingBottom: '0 !important',
        },
        racpadTab: {
            display: 'flex',
        },
        racCol12: {
            flex: '0 0 auto',
            width: '100%',
        },
        racCol6: {
            flex: '0 0 auto',
            width: '50%',
        },
        racCol10: {
            flex: '0 0 auto',
            width: '83.3333333333%',
        },
        racCol2: {
            flex: '0 0 auto',
            width: '16.666666666%',
        },
        racCol8: {
            flex: '0 0 auto',
            width: '66.6666666667%',
        },
        alertWidgetContainer: {
            flexWrap: 'unset',
            overflow: 'auto',
            display: 'flex',
        },
        alertWidgetStyle: {
            backgroundColor: '#FCE5E5',
            borderRadius: '40px',
            color: '#4B4B4B',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            fontFamily: 'OpenSans-semibold',
            fontSize: '14px',
            padding: '4px 15px 6px 15px',
            marginTop: '1px',
            float: 'left',
            whiteSpace: 'nowrap',
        },
        alertLoaderSize: {},
        widthAuto: {
            width: 'auto',
        },
        custGridMargin: {
            marginBottom: '100px',
        },
        custDateField: {
            width: '100%',
            float: 'left',
            borderRadius: '6px',
            padding: '0.2875rem 0.7rem 0.2875rem 0.7rem',
            border: '1px solid #d9d9d9',
        },
        datePickerImg: {
            float: 'right',
            width: '14px',
            height: '19px',
        },
        RACCUSTOMERGridTextAlign: {
            textAlign: 'end',
        },
        navLinkItem: {
            borderBottom: '5px solid transparent',
            fontSize: '14px',
            fontFamily: 'OpenSans-semibold',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            position: 'relative',
        },
        navLinkActive: {
            color: '#2468ff',
            borderBottom: '5px solid #2468ff',
            '&:before': {
                content: "''",
                position: 'absolute',
                height: '5px',
                width: '10px',
                background: '#2468ff',
                left: '-5px',
                bottom: '-5px',
                borderTopLeftRadius: '5px',
            },
            '&:after': {
                content: "''",
                position: 'absolute',
                height: '5px',
                width: '10px',
                background: '#2468ff',
                right: '-5px',
                bottom: '-5px',
                borderTopRightRadius: '5px',
            },
        },
        formCheckToggle: {
            display: 'block',
            minHeight: '1.5rem',
            marginBottom: '0.125rem',
        },
        formLabelCustom: {
            float: 'left',
            width: '100%',
            fontSize: '14px',
            fontFamily: 'OpenSans-semibold',
        },
        formLabelInline: {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            display: 'inline-block',
            paddingLeft: 0,
        },
        swThemeDefault: {
            width: '100%',
            position: 'relative',
        },
        swThemeDefaultContainer: {
            flex: '0 0 auto',
            width: '100%',
            background: `${RACCOLOR.WHITE}`,
        },
        smartWizard: {
            display: 'inline-block',
            listStyle: 'none',
        },
        smartWizardList: {
            textAlign: 'center',
            position: 'relative',
            marginRight: theme.typography.pxToRem(20),
            paddingLeft: theme.typography.pxToRem(0),
            paddingRight: theme.typography.pxToRem(0),
            width: 'auto',
            display: 'inline-block',
            '&:after': {
                borderBottom: '2px solid #cecece',
                content: '""',
                position: 'absolute',
                width: '28px',
                right: '-16px',
                top: '50%',
            },
            '&:last-child': {
                '&:after': {
                    border: 'none',
                },
            },
        },
        smartWizardListLinkActive: {
            textAlign: 'center',
            position: 'relative',
            marginRight: theme.typography.pxToRem(20),
            width: 'auto',
            color: `${RACCOLOR.DEEP_SKY_BLUE}`,
            cursor: 'pointer',
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
        },
        smartWizardListLinkInActive: {
            textAlign: 'center',
            position: 'relative',
            marginRight: theme.typography.pxToRem(20),
            width: 'auto',
            cursor: 'pointer',
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
            color: '#8e8e8e',
        },
        borderActive: {
            border: '2px solid #d9d9d9',
            padding: '4px 10px',
            margin: '0 5px',
            backgroundColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
            borderColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
            borderRadius: '50px',
            color: `${RACCOLOR.WHITE}`,
        },
        borderDefault: {
            border: '1px solid #d9d9d9',
            padding: '4px 10px',
            borderRadius: '50px',
            margin: '0 5px',
            color: '#999999',
        },
        navInactive: {
            textAlign: 'center',
            position: 'relative',
            marginRight: theme.typography.pxToRem(20),
            width: 'auto',
            cursor: 'pointer',
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
            color: '#8e8e8e',
        },
        done: {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: '#5ae286',
            color: 'transparent',
            borderColor: '#5ae286',
            padding: '4px',
        },
        racLoginAlert: {
            color: 'red',
            fontFamily: 'OpenSans-semibold',
            fontSize: '14px',
        },
        racSecFactorTextbox: {
            width: '150%',
        },
        racBtnDisabled: {
            pointerEvents: 'none',
            opacity: 0.65,
        },
        racErrorIcon: {
            width: ' 50px',
        },
        racErrorFunction: {
            fontFamily: 'OpenSans-semibold',
            marginBottom: '20px',
            marginTop: '10px',
        },
        racPinError: {
            color: 'red',
            marginTop: '5px',
            marginLeft: '0.5rem',
            fontFamily: 'OpenSans-semibold',
        },
        racPinContainer: {
            margin: '0 auto',
            width: '94%',
        },
        racPinLabel: {
            fontFamily: 'OpenSans-semibold',
            color: '#000000',
            marginLeft: '0.5rem',
            marginBottom: '3px',
        },
        racPinField: {
            height: '45px',
            width: '45px',
            marginRight: '1rem',
            marginLeft: '0.5rem',
            textAlign: 'center',
            borderRadius: '0.6rem',
            border: '1px solid #adb5bd',
            fontSize: 'x-large',
        },
        custCheckbox: {
            color: '#0d6efd',
        },
        subTitle: {
            color: '#212529',
            fontFamily: 'OpenSans-regular',
            fontWeight: 700,
            fontSize: '14px',
            marginBottom: '10px',
        },
        breadcrumb: {
            display: 'flex',
            flexWrap: 'wrap',
            padding: 0,
            marginBottom: theme.typography.pxToRem(10),
            listStyle: 'none',
        },
        breadcrumbItem: {
            width: 'auto',
            fontFamily: 'OpenSans-semibold',
            fontSize: '14px',
        },
        breadcrumbItemActive: {
            width: 'auto',
            fontFamily: 'OpenSans-semibold',
            fontSize: '14px',
            color: '#3182FF',
            paddingLeft: '15px',
            paddingRight: 0,
        },
        breadCrumbMarg: {
            marginLeft: '20px',
        },
        breadcrumbArrow: {
            '&:before': {
                content: '""',
                width: 0,
                height: 0,
                borderTop: '5px solid transparent',
                borderLeft: '7px solid #707070',
                borderBottom: '5px solid transparent',
                marginTop: 0,
                float: 'left',
                paddingRight: '13px',
                color: '#6c757d',
                paddingLeft: 0,
            },
        },
        badge: {
            display: 'inline-block',
            marginTop: '6px',
            padding: '5px 10px',
            fontSize: '0.75em',
            fontWeight: 700,
            lineHeight: 1,
            color: '#fff',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            verticalAlign: 'baseline',
            borderRadius: '20px',
        },
        badgeRed: {
            backgroundColor: '#e85c5c',
        },
        badgeOrange: {
            backgroundColor: `${RACCOLOR.MARIGOLD}`,
        },
        badgeGreen: {
            backgroundColor: `${RACCOLOR.VITAL_GREEN}`,
        },
        racDEDate: {
            fontSize: '13px',
            color: '#5b6d83',
            display: 'block',
            fontFamily: 'OpenSans-semibold',
        },
        custApprovalLabel: {
            textAlign: 'left',
            float: 'left',
            fontSize: '12px',
            fontFamily: 'OpenSans-semibold',
            color: '#2279fd',
            width: '100%',
        },
        custDigit: {
            width: '87%',
            '& input': {
                textAlign: 'right',
            },
            borderBottomLeftRadius: '0px',
            borderTopLeftRadius: '0px',
        },
        currencyDollarField: {
            width: '13%',
            paddingTop: '7px',
            paddingBottom: '6px',
            paddingLeft: theme.typography.pxToRem(10),
            paddingRight: theme.typography.pxToRem(10),
        },
        formCheckBoxInput: {
            width: '1em',
            height: '1em',
            marginTop: '0.25em',
            verticalAlign: 'top',
            backgroundColor: '#ffffff',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            border: '1px solid rgba(0, 0, 0, 0.25)',
            appearance: 'none',
            float: 'left',
            marginLeft: '-1.5em',
            '&:checked': {
                backgroundColor: '#0d6efd',
                borderColor: '#0d6efd',
            },
        },
        dateField: {
            padding: 0,
            border: 'none',
            width: 0,
        },

        gridClassToggleReverse: {
            display: 'inline-flex',
            flexDirection: 'row-reverse',
        },
        gridClassLabel: {
            marginLeft: theme.typography.pxToRem(10),
            marginRight: theme.typography.pxToRem(10),
        },
        switchLabel: {
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
        },
        textCenterSSN: {
            '& input': {
                textAlign: 'center',
            },
        },
        ssnPaddingTop: {
            paddingTop: '4px !important',
        },
        addCustomerMargin: {
            marginTop: '-3px !important',
        },
        hideField: {
            display: 'none !important',
        },
        fontColor: {
            color: '#545454 !important',
        },
        w100: {
            width: '100%',
        },
        card: {
            borderRadius: '16px!important',
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
            width: '100%',
        },
        infoTextStyle: {
            color: 'gray',
            fontSize: theme.typography.pxToRem(13),
            textAlign: 'center',
        },
        cardHeight: {
            height: theme.typography.pxToRem(271),
        },
        px2: {
            paddingRight: theme.typography.pxToRem(8),
            paddingLeft: theme.typography.pxToRem(8),
        },
        textWrapStyle: {
            whiteSpace: 'nowrap',
        },
        mtcustom: {
            marginTop: theme.typography.pxToRem(19),
        },
        successicon: {
            paddingTop: '3px!important',
        },
        dropdownitemcustom: {
            color: '#2468FF',
            fontFamily: 'OpenSans-bold',
            backgroundColor: 'white',
            textDecoration: 'none',
            cursor: 'pointer',
        },
        popUpHide: {
            display: 'none',
        },
        customMenuContainer: {
            border: '1px solid #2468FF',
        },
        popUp: {
            position: 'absolute',
            bottom: '100%',
            backgroundColor: 'white',
            width: '200px',
            padding: '15px',
            '& li': {
                listStyle: 'none',
            },
        },
        semiBold: {
            fontFamily: 'OpenSans-semibold',
        },
        agrementGridMC: {
            '& > tbody > tr': {
                background: 'transparent',
            },
            '& td': {
                padding: '10px 5px',
            },
        },
        agreementCategory: {
            color: '#212529',
            fontSize: '16px',
            fontFamily: 'OpenSans-bold',
            marginBottom: '10px',
        },
        hideRental: {
            width: '165px',
            position: 'absolute',
            left: '0px',
            bottom: '100%',
            border: '1px solid #2468FF',
            backgroundColor: 'white',
        },
        popupRental: {
            width: '165px',
            position: 'absolute',
            left: '0px',
            bottom: '100%',
            border: '1px solid #2468FF',
            backgroundColor: 'white',
        },
        bgBlue: {
            backgroundColor: 'white',
        },
        pr: {
            position: 'relative',
        },
        parentclass: {
            float: 'left',
            width: '100%',
        },

        flexratio: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        racpadLinkCustomer: {
            fontFamily: 'OpenSans-bold',
            fontSize: '14px',
            color: '#2179FE',
            textDecoration: 'none',
        },
        floatRight: {
            float: 'right',
        },
        agrInfoPanelviewagr: {
            fontSize: '13px',
            color: '#5b6d83',
            fontFamily: 'OpenSans-semibold',
            lineHeight: 'unset',
            float: 'left',
        },
        overall: {
            float: 'left',
        },
        over1: {
            float: 'left',
        },
        check: {
            width: `100% !important`,
            float: 'left',
        },
        approvalAmt: {
            color: `#2179FE`,
            fontSize: theme.typography.pxToRem(13),
            textDecoration: 'none',
            cursor: 'pointer',
            fontFamily: 'OpenSans-semibold',
        },
        expDate: {
            display: 'block',
            padding: '0.1rem 1rem',
            textDecoration: 'none',
            fontSize: '14px',
            marginRight: '5px',
            fontFamily: 'OpenSans-semibold',
            color: `#5B6D83`,
        },
        racpadLink: {
            color: '#2179FE',
            fontSize: theme.typography.pxToRem(14),
            textDecoration: 'none',
            cursor: 'pointer',
            fontFamily: 'OpenSans-semibold',
        },
        dropdowntoggle: {
            display: 'inline-block',
            marginLeft: '0.255em',
            verticalAlign: '0.255em',
            content: '',
            borderTop: '0.3em solid',
            borderRight: '0.3em solid transparent',
            borderBottom: '0',
            borderLeft: '0.3em solid transparent',
        },
        me1: {
            marginRight: theme.typography.pxToRem(4),
        },
        pr: {
            position: 'relative',
        },
        hideRental: {
            width: '165px',
            position: 'absolute',
            left: '0px',
            bottom: '100%',
            border: '1px solid #2468FF',
            backgroundColor: 'white',
        },
        popupRental: {
            width: '165px',
            position: 'absolute',
            left: '0px',
            bottom: '100%',
            border: '1px solid #2468FF',
            backgroundColor: 'white',
        },
        dropdownitemcustom: {
            color: '#2468FF',
            fontFamily: 'OpenSans-bold',
            backgroundColor: 'white',
            textDecoration: 'none',
            cursor: 'pointer',
        },
        bgBlue: {
            backgroundColor: 'white',
        },
        GridLoader: {
            textAlign: 'center',
            marginBottom: theme.typography.pxToRem(20),
            fontSize: theme.typography.pxToRem(16),
            color: `${RACCOLOR.RICH_BLACK}`,
            margin: '30px 0px',
        },
        fixTableHead: {
            overflowY: 'auto',
            maxHeight: '205px',
            '& th': {
                position: 'sticky',
                top: 0,
                backgroundColor: '#ffffff',
                fontFamily: 'OpenSans-semibold',
                zIndex: 1,
                padding: '0.9375rem 0.2rem',
                whiteSpace: 'nowrap',
            },
            '& tbody': {
                '& tr': {
                    position: 'sticky',
                    top: 10,
                    backgroundColor: '#ffffff',
                },
                '& td': {
                    fontFamily: 'OpenSans-semibold',
                    padding: '0.9375rem 0.2rem',
                    color: '#4A5174',
                },
            },
        },
        fixTableHeight300: {
            maxHeight: '300px',
        },
        fixTableHeight400: {
            maxHeight: '400px',
        },
        title: {
            color: '#212529',
            fontFamily: 'OpenSans-bold',
            fontSize: '16px',
            marginBottom: '10px',
        },
        ssnBorderRed: {
            border: '1px solid red',
            borderRadius: '6px',
            outline: 'none',
        },
        racGlobalSearchBtn: {
            cursor: 'pointer',
            width: '25px',
            float: 'left',
            paddingTop: '0.2rem',
        },
        widgetTitle: {
            fontSize: theme.typography.pxToRem(16),
            fontFamily: 'OpenSans-bold',
            color: `${RACCOLOR.WASHED_BLACK}`,
        },
        spacer: {
            marginTop: theme.typography.pxToRem(16),
            marginBottom: theme.typography.pxToRem(16),
            paddingLeft: theme.typography.pxToRem(8),
            paddingRight: theme.typography.pxToRem(8),
        },
        card: {
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
        },
        customerFloatright: {
            float: 'right',
        },
        customerFloatleft: {
            float: 'left',
        },
        foc: {
            '&:focus': {
                borderColor: '#80bdff',
                boxShadow: '1px 1px 6px 3px #80bdff',
            },
        },
        focforLink: {
            '&:focus': {
                border: '1px solid #80bdff',
                outline: 'none',
                borderRadius: '5px',
            },
        },
        focforLinkPaymentIssue: {
            outline: 'none',
        },
        colRight: {
            textAlign: 'right',
        },
        fixedBottom: {
            position: 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1030,
            backgroundColor: `${RACCOLOR.WHITE}`,
            boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
            padding: '1rem',
        },
        customerRow: {
            width: '100%',
        },
        customerJustifycontentcenter: {
            justifyContent: 'center',
        },

        customerToolbar: {
            position: 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1030,
            backgroundColor: `${RACCOLOR.WHITE}`,
            boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
            paddingLeft: theme.typography.pxToRem(16),
            paddingRight: theme.typography.pxToRem(16),
            paddingTop: theme.typography.pxToRem(16),
            paddingBottom: theme.typography.pxToRem(16),
        },
        spacerP0: {
            padding: theme.typography.pxToRem(0),
        },
        spacerP2: {
            padding: theme.typography.pxToRem(16),
        },
        spacerMT0: {
            marginTop: theme.typography.pxToRem(0),
        },
        spacerMB3: {
            marginBottom: theme.typography.pxToRem(16),
        },
        spacerMT3: {
            marginTop: theme.typography.pxToRem(16),
        },
        spacerMT32: {
            marginTop: '2rem',
        },
        spacerMT4: {
            marginTop: theme.typography.pxToRem(24),
        },
        spacerMB1: {
            marginBottom: theme.typography.pxToRem(4),
        },
        spacerMT1: {
            marginTop: theme.typography.pxToRem(4),
        },
        spacerMR2: {
            marginRight: theme.typography.pxToRem(8),
        },
        spacerMR1: {
            marginRight: theme.typography.pxToRem(16),
            marginTop: theme.typography.pxToRem(16),
        },
        spacerPX2: {
            paddingLeft: theme.typography.pxToRem(8),
            paddingRight: theme.typography.pxToRem(8),
        },
        spacerMB3PX1: {
            marginBottom: theme.typography.pxToRem(16),
            paddingLeft: theme.typography.pxToRem(4),
            paddingRight: theme.typography.pxToRem(4),
        },
        spacerPT1: {
            paddingTop: theme.typography.pxToRem(16),
        },
        spacerPB2: {
            paddingBottom: theme.typography.pxToRem(8),
        },
        spacerMB2: {
            marginBottom: theme.typography.pxToRem(8),
        },
        spacerMS2: {
            marginLeft: theme.typography.pxToRem(8),
        },
        ms2: {
            marginLeft: theme.typography.pxToRem(8),
        },
        mb2: {
            marginBottom: theme.typography.pxToRem(8),
        },
        mb3: {
            marginBottom: theme.typography.pxToRem(16),
        },
        mt1: {
            marginTop: theme.typography.pxToRem(4),
        },
        mt4: {
            marginTop: theme.typography.pxToRem(24),
        },
        customerDisplayinline: {
            display: 'inline-block',
        },
        customerColmd: {
            flex: '0 0 auto',
            width: '100%',
            backgroundColor: `${RACCOLOR.WHITE}`,
        },
        sideTitle: {
            fontSize: theme.typography.pxToRem(18),
            color: `${RACCOLOR.WASHED_BLACK}`,
            marginBottom: theme.typography.pxToRem(10),
            // eslint-disable-next-line sonarjs/no-duplicate-string
            fontFamily: 'OpenSans-bold',
        },
        customerTablecellbgcolor: {
            backgroundColor: `${RACCOLOR.WHITE}`,
        },
        customerAccordionopen: {
            borderLeft: '2px solid #7bbffc',
            boxShadow: '0px -1px 2px 0px #eaeff5',
            borderTopLeftRadius: theme.typography.pxToRem(12),
            borderTopRightRadius: theme.typography.pxToRem(12),
        },
        customerAccordiontogglev3: {
            backgroundImage: `url(${accordianCloseIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: theme.typography.pxToRem(20),
            cursor: 'pointer',
            width: theme.typography.pxToRem(20),
            height: theme.typography.pxToRem(20),
            display: 'block',
        },
        accordionIconGrid: {
            backgroundImage: `url(${accordianOpenIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '23px',
            cursor: 'pointer',
            width: '23px',
            height: '23px',
            display: 'block',
            float: 'left',
            marginTop: '1px',
        },
        accordionGridClose: {
            backgroundImage: `url(${accordianCloseIcon})`,
        },
        customerAccordionarrowchange: {
            backgroundImage: `url(${accordianCloseIcon})`,
            backgroundRepeat: 'no-repeat',
        },
        customerHiddenrow: {
            backgroundColor: `${RACCOLOR.WHITE}`,
        },
        customerAccordianopen: {
            borderLeft: '5px solid #7bbffc',
            boxShadow: '0px -1px 2px 0px #eaeff5',
            borderTopLeftRadius: theme.typography.pxToRem(12),
            borderTopRightRadius: theme.typography.pxToRem(12),
        },
        customerCheckbox: {
            color: `${RACCOLOR.DEEP_SKY_BLUE}`,
        },
        customerRaclink: {
            color: `${RACCOLOR.BLUE_CRAYOLA}`,
            textDecoration: 'none',
            fontFamily: 'OpenSans-semibold',
            cursor: 'pointer',
        },
        customerLinebreak: {
            whiteSpace: 'nowrap',
        },
        customerPointercursor: {
            cursor: 'pointer',
        },
        customerTextend: {
            textAlign: 'right',
        },
        customerTextcenter: {
            textAlign: 'center',
        },
        customerRacpopup: {
            fontFamily: 'OpenSans-bold',
            fontSize: theme.typography.pxToRem(16),
            letterSpacing: '0px',
        },
        spacerMS1: {
            marginLeft: theme.typography.pxToRem(4),
        },
        customerLineBreak: {
            whiteSpace: 'nowrap',
        },
        checkBoxwidth: {
            width: theme.typography.pxToRem(10),
        },
        customerTextsubtilegrid: {
            fontFamily: 'OpenSans-bold',
            fontSize: theme.typography.pxToRem(18),
            color: '#000',
        },
        customerNorecords: {
            textAlign: 'center',
            marginTop: theme.typography.pxToRem(30),
            marginBottom: theme.typography.pxToRem(30),
            fontSize: theme.typography.pxToRem(16),
            color: `${RACCOLOR.RICH_BLACK}`,
        },
        customerNorecordsAgr: {
            textAlign: 'center',
            fontSize: theme.typography.pxToRem(16),
            color: `${RACCOLOR.RICH_BLACK}`,
        },
        customerNorecordsAlert: {
            textAlign: 'center',
            fontSize: theme.typography.pxToRem(16),
            color: `${RACCOLOR.RICH_BLACK}`,
            marginRight: '10px !important',
        },
        alertLoader: {
            width: '30px !important',
            height: '30px !important',
            marginTop: '10px',
        },
        formLabel: {
            marginBottom: '2px',
            float: 'left',
            color: '#111111',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
        },
        fixedBottomAddCo: {
            position: 'sticky',
            bottom: '-19px',
            right: '0px',
            backgroundColor: 'white',
            padding: '14px 0px',
        },

        formLabelAddCo: {
            marginBottom: '0.2rem',
            color: '#111111',
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(14),
        },

        mandatoryfield: {
            color: `${RACCOLOR.MAXIMUM_RED}`,
        },
        demooooo: {
            color: 'yellow',
        },
        RACPOPMsg: {
            fontFamily: 'OpenSans-bold',
            fontSize: theme.typography.pxToRem(16),
            letterSpacing: 0,
        },
        spacerME2: {
            marginRight: theme.typography.pxToRem(16),
        },
        me2: {
            marginRight: theme.typography.pxToRem(8),
        },
        mt5: {
            marginTop: theme.typography.pxToRem(48),
        },
        ps2: {
            paddingLeft: theme.typography.pxToRem(8),
        },
        spacerMT2: {
            marginTop: theme.typography.pxToRem(16),
        },
        semiBold: {
            fontFamily: 'OpenSans-semibold',
        },
        formCheck: {
            minHeight: '1.5rem',
            marginBottom: '0.125rem',
            display: 'inline-block',
            marginRight: '1rem',
            paddingLeft: 0,
        },
        formCheck2: {
            minHeight: '1.5rem',
            marginBottom: '0.125rem',
            display: 'inline-block',
            paddingLeft: 0,
        },
        textRight: {
            textAlign: 'right',
        },
        textCenterSSN: {
            '& input': {
                textAlign: 'center',
            },
        },
        textCenter: {
            textAlign: 'center',
        },
        w100: {
            width: '100%',
        },
        floatLeft: {
            float: 'left',
        },
        floatRight: {
            float: 'right',
        },
        justifyContentCenter: {
            justifyContent: 'center',
        },
        bold: {
            fontFamily: 'OpenSans-bold',
        },
        font16: {
            fontSize: '16px',
        },
        customerTable: {
            '& tr': {
                backgroundColor: 'transparent',
            },
        },
        paymentIssue: {
            '& td': {
                backgroundColor: '#ffefef',
            },
            '& td:first-child': {
                borderTopLeftRadius: '7px',
                borderBottomLeftRadius: '7px',
            },
            '& td:last-child': {
                borderTopRightRadius: '7px',
                borderBottomRightRadius: '7px',
            },
        },
        racpadAgrLink: {
            borderLeft: '3px solid transparent',
            paddingLeft: '10px',
            borderRadius: '2px',
        },
        racpadPaymentFails: {
            position: 'relative',
            '&:before': {
                content: '""',
                height: '20px',
                width: '3px',
                background: '#fd6a63',
                position: 'absolute',
                left: 0,
                top: 0,
                borderTopRightRadius: '2px',
                borderBottomRightRadius: '2px',
            },
        },
        racpadPaymentInActive: {
            borderColor: '#b1adac',
        },
        racpadPaymentSuccess: {
            position: 'relative',
            '&:before': {
                content: '""',
                height: '20px',
                width: '3px',
                background: '#56e0d8',
                position: 'absolute',
                left: 0,
                top: 0,
                borderTopRightRadius: '2px',
                borderBottomRightRadius: '2px',
            },
        },
        racstrapTablecellBgColor: {
            backgroundColor: `${RACCOLOR.WHITE}`,
        },
        racpadLink: {
            color: '#2468ff',
            fontSize: '14px',
            textDecoration: 'none',
            cursor: 'pointer',
        },
        racpadClubLink: {
            color: '#000000',
            fontSize: '14px',
            textDecoration: 'none',
        },
        lineBreak: {
            whiteSpace: 'nowrap',
        },
        agreementRow: {
            borderBottom: '2px solid white',
        },
        RACLoaderPage: {
            textAlign: 'center',
            marginTop: '250px',
            fontSize: '16px',
            marginLeft: '30px',
            marginRight: '30px',
            outline: 'none',
        },
        racFixedFooterMargin: {
            marginBottom: '120px',
        },
        racGrid: {
            '& th': {
                fontFamily: 'OpenSans-semibold',
                fontSize: '14px',
                color: '#000',
            },
            '& td': {
                fontFamily: 'OpenSans-semibold',
                fontSize: '14px',
                color: '#4A5174',
            },
        },
        txtSubtileGrid: {
            fontFamily: 'OpenSans-bold',
            fontSize: '18px',
            color: '#000000',
        },
        badgeContainer: {
            padding: '1px 13px',
            borderRadius: '10px',
            backgroundColor: '#ddf8ed',
            color: '#10523e',
            marginLeft: ' 5px',
        },
        px2: {
            paddingRight: theme.typography.pxToRem(8),
            paddingLeft: theme.typography.pxToRem(8),
        },
        spacerP1: {
            padding: '.375rem .75rem',
        },
        mb100: {
            marginBottom: '120px',
        },
        loaderStyle: {
            border: 'none',
            backgroundColor: 'transparent',
            // userFocus: 'none',
            // MozUserFocus: 'none',
            outline: 'none',
        },
        ssnpadding: {
            paddingTop: '0px',
        },
        datePicker: {
            paddingTop: '9px !important',
            marginBottom: '0px !important',
            '& div': {
                '& div': {
                    '& fieldset': {
                        borderColor: '#c4c4c4 !important',
                    },

                    '& input': {
                        padding: '6px',

                        paddingLeft: '12px',
                    },
                },
            },
        },
        datePickerAddCo: {
            marginBottom: '0px !important',
            marginTop: '4px !important',
        },
        datePickerMB: {
            marginBottom: '1px !important',
            marginTop: '4px !important',
        },
        titleColor: {
            color: 'gray',
        },
        infoTextStyle: {
            color: 'gray',
            fontSize: theme.typography.pxToRem(13),
            textAlign: 'center',
        },
        cardHeight: {
            height: theme.typography.pxToRem(271),
        },
        pt2: {
            paddingTop: theme.typography.pxToRem(8),
        },
        mb5: {
            marginBottom: theme.typography.pxToRem(48),
        },
        my4: {
            marginTop: theme.typography.pxToRem(24),
            marginBottom: theme.typography.pxToRem(24),
        },
        tablebggreen: {
            backgroundColor: '#F0FDF5',
        },
        masterLoader: {
            position: 'fixed',
            backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 1050,
            textAlign: 'center',
            margin: 0,
        },
        Loader: {
            display: 'block',
            position: 'fixed',
            zIndex: 1051,
            top: '50%',
            right: '50%',
        },
        marginbottom2: {
            marginBottom: '6px!important',
        },
        pb0: {
            paddingBottom: '!important',
        },
        dispInlineFlex: {
            display: 'inline-flex',
        },
        txtStart: {
            textAlign: 'start',
        },
        ssnPaddingCocust: {
            padding: '6px 8px 8px 8px !important',
        },
        ssnTextField: {
            '& input': {
                ['@media (min-width:768px) and (max-width:1280px)']: {
                    padding: '7px 9px!important',
                },
            },
        },
        agreementGrid: {
            width: '94%',
            margin: '0 auto !important',
            borderCollapse: 'separate',
            borderSpacing: '0 5px !important',
        },
        accordianWidth: {
            width: '42px',
        },
        cusomerInfo: {
            borderLeft: '7px solid #7bbffc',
            boxShadow: '0 1px 25px 0 rgb(0 0 0 / 7%)',
        },
        agreementGridInnerRow: {
            marginLeft: '-1.5px',
            boxShadow: '4px 4px 4px #eaeaea',
            borderLeft: '5px solid #7bbffc',
            paddingBottom: '2rem',
            marginBottom: '2rem',
        },
        textAlign: {
            textAlign: 'left',
        },
        borderRadiusZero: {
            borderRadius: '0px !important',
        },
        gridBorderBottom: {
            borderBottom: '1px solid #eaeef5',
        },
        RACPOPMsgforCC: {
            fontFamily: 'OpenSans-semibold',
            fontSize: theme.typography.pxToRem(16),
            letterSpacing: 0,
        },
        clrntnstyle: {
            marginTop: '17px!important',
            padding: '6px 20px !important',
        },
        pl40: {
            paddingLeft: '32px!important',
            color: '#212529',
            fontSize: '16px',
            fontFamily: 'OpenSans-bold',
            marginBottom: '10px',
        },
        pl28: {
            paddingLeft: '28px!important',
        },

        hyperLink: {
            cursor: 'pointer',
            color: RACCOLOR.BLUE_CRAYOLA,
            textDecoration: 'underline',
            textDecorationColor: RACCOLOR.BLUE_CRAYOLA,
        },
        title1: {
            color: '#000',
            fontFamily: 'OpenSans-bold',
            fontSize: theme.typography.pxToRem(16),
            marginBottom: theme.typography.pxToRem(10),
        },
        cardTile: {
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
        },
        payScheduleHighlight: {
            backgroundColor: `#2179FE`,
        },
        cursorPointer: {
            cursor: 'pointer',
        },
        cardTileStyle: {
            borderStyle: 'solid',
            borderWidth: 'thin',
            borderColor: '#22a3ff',
        },
        justifyLabel: {
            justifyContent: 'center',
        },
        paySchedulePeriodHighlight: {
            color: `${RACCOLOR.WHITE}`,
            fontSize: '15px',
            textAlign: 'center',
        },
        paySchedulePeriodNotHighlight: {
            textAlign: 'center',
            fontSize: '15px',
            color: '#22a3ff',
        },
        alertwidget: {
            borderRadius: '15px !important',
            background: '#eff4ff',
        },
        mb1p: {
            marginBottom: '1%',
        },

        h65: {
            height: '65px',
        },
        paymentAlignCenter1: {
            display: 'flex',
            alignItems: 'center',
        },

        raccollg6_1: {
            flex: '0 0 auto',
            width: '15%',
        },
        titletxtstyle1: {
            fontSize: theme.typography.pxToRem(18),
        },
        px20: {
            padding: 10,
        },
        payScheduleNotHighlight: {
            backgroundColor: `${RACCOLOR.WHITE}`,
        },
        gridLabel: {
            color: '#6C86A5',
            fontFamily: 'OpenSans-bold',
            fontSize: theme.typography.pxToRem(14),
        },
        Pl15: {
            paddingLeft: '15Px',
        },
        Pl25: {
            paddingLeft: '25Px !important',
        },
        mb1: {
            marginBottom: theme.typography.pxToRem(4),
        },
        px0: {
            paddingRight: 0,
            paddingLeft: 0,
        },
        paymentAlignCenter: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        raccollg6: {
            flex: '0 0 auto',
            width: '50%',
        },
        titletxtstyle: {
            fontSize: theme.typography.pxToRem(20),
        },
        pe4: {
            paddingRight: theme.typography.pxToRem(24),
        },
        mt2: {
            marginTop: '0.5rem !important',
        },
        py4: {
            paddingTop: theme.typography.pxToRem(24),
            paddingBottom: theme.typography.pxToRem(24),
        },
        mx1: {
            marginRight: theme.typography.pxToRem(4),
            marginLeft: theme.typography.pxToRem(4),
        },
        buttonStyle: {
            borderRadius: '45%',
        },
        textAlignCenter: {
            textAlign: 'center',
        },
        iconWidth1: {
            width: '35px',
        },
        iconWidth2: {
            width: '25px',
        },
        marginLeft: {
            marginLeft: '10px',
        },
        fontWidth: {
            fontSize: '10px'
        },
        noWrap: {
            whiteSpace: 'nowrap'
        },
        pointer: {
            cursor: 'pointer'
        },
        removeHoverStyle: {
            '& .RAC-PAYMENT--MuiButtonBase-root:hover': {
                backgroundColor: 'transparent',
            },
        },
        wrapContent1: {
            display: 'flex',
            justifyContent: 'space-evenly'
        },
        wrapContent: {
            display: 'flex',
            justifyContent: 'space-evenly'
        },
        wrapContentSA: {
            display: 'flex',
            justifyContent: 'space-around'
        },
        centerButton: {
            display: 'flex',
            justifyContent: 'space-around'
        },
        outerWidth: {
            width: '33.3%'
        },
        MR1: {
            marginRight: '10px',
        },
        customerRacpopup1: {
            fontFamily: 'OpenSans-bold',
            fontSize: '17px',
            letterSpacing: '0px',
            marginBottom: '17px'
        },
        tooltipcss: {
            height: '35px',
            marginLeft: '4px',
            marginBottom: '-2px',
            top: '0',
            positon: 'absolute',
        },
        tooltip: {
            backgroundColor: 'black',
            color: 'white',
            fontSize: theme.typography.pxToRem(12),
            borderRadius: '5px',
        },
        height100: {
            height: '72px'
        },
        toolTipStyle: {
            top: '-45px',
            left: '0',
            backgroundColor: '#ECF0F3',
            color: 'black'
        },
        customModal3: {
            '& div': {
                '& div': {
                    maxWidth: '450px',
                    maxHeight: '700px',
                },
            },
        },
        customModal4: {
            '& div': {
                '& div': {
                    maxWidth: '700px',
                    maxHeight: '700px',
                },
            },
        },
        overlay: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)', /* Semi-transparent black overlay */
            pointerEvents: 'none', /* Allow clicks to pass through */
            cursor: 'default'
        },
        positionRelative: {
            position: 'relative'
        },
        pointerEvents: {
            pointerEvents: 'none', /* Allow clicks to pass through */
            cursor: 'default'
        },
        customerRacpopup12: {
            fontFamily: 'OpenSans-bold',
            fontSize: '17px',
            letterSpacing: '0px',
        },
        arrowStyle: {
            color: '#ECF0F3'
        },
        overlay1: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
        },
        blur: {
            opacity: '0.5',
        },
        textAlignRight: {
            textAlign: 'right'
        },
        buttonsPadding: {
            padding: '9px 36px',
        },
        mr25p: {
            marginRight: '0.5%',
        },
        mt11px: {
            marginTop: '11px'
        },
        pt9px: {
            paddingTop: '9px'
        },
        pl9px: {
            paddingLeft: '9px'
        },
        ml20px: {
            marginLeft: '20px'
        },
        p13px: {
            padding: '13px'
        },
        cursorNone: {
            pointer: 'none'
        },
        bottomAlign: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            height: '100%',
            alignItems: 'flex-start'
        },
        p25px: {
            padding: '25px'
        },
        pb10px: {
            paddingBottom: '10px'
        },
        displayFlex: {
            display: 'flex'
        },
        justifyContentRight: {
            justifyContent: 'right'
        },
        mt30px: {
            marginTop: '30px'
        },
        divider: {
            width: '3px',
            background: '#CFE2FF',
            marginTop: '6px',
            marginBottom: '6px'
        },
        notesCharacterCount: {
            marginTop: theme.typography.pxToRem(5),
        },
        otherTextContent: {
            width: '100%',
        }
    }));
    const classes = UseClasses();
    return classes;
}