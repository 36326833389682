/* eslint-disable */
import { Grid, RACButton } from "@rentacenter/racstrap";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { EmployerDetails, ParamType } from "../../../interface/globalInterface";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import SecondFactor from "../../../../racstrapComponent/manageCustomer/secondAuthen";
import {
  DATE_FORMAT,
  EMPTY_STRING,
  MODULE_NAME,
  STRING_NO,
  STRING_YES,
} from "../../../constants/constants";
import {
  updateArrayOfObjectState,
  updateCustomerAndVerification,
} from "../Utils";
import { useParams } from "react-router-dom";
import moment from "moment";
import { DynamicVerifiedStatus } from "../DynamicVerifiedStatus";
import { DynamicAlertPopup } from "../DynamicAlertPopup";

export function HandleEdit({ value, setterData }: any) {

  const history = useHistory()
  let {
    setUpdatedEmployerDetails,
    updatedEmployerDetails,
    setScreenLoader,
    setGetCustomerInfoResponse,
    setGetApprovalRes,
    currentRole,
    currentEmpId,
    setEmployerDetailsErrorMessage,
    incomeDoc,
    setincomeDocCopy,
    configurations,
    incomeDocCopy,
    isIncomeDocDeleted,
    incomeDocId,
    setIncomeDoc,
    setIsIncomeDocDeleted,
    setIncomeDocId,
    setVerificationDocuments
  } = useContext(UpdateCustomerContext);
  const [showAuthentication, setShowAuthentication] = useState<boolean>(false);
  let { index, employerDetails, setEmployerDetails, checkMandatoryFields, actualDetails, updatedEmpDetails, setErrorMessage } =
    setterData;
  const { customerId } = useParams<ParamType>();
  const classes = globalStyles();
  const [errorPopup, setErrorPopup] = useState<boolean>(false);


  const validateEmployerDetails: boolean =
    employerDetails[index]?.basicInfo?.companyName && employerDetails[index]?.basicInfo?.companyName !== "" &&
    (employerDetails[index]?.basicInfo?.phoneNumber
      ? employerDetails[index]?.basicInfo?.phoneNumber?.length > 9
      : true) &&
    employerDetails[index]?.incomeVerification?.paydayFrequency && employerDetails[index]?.incomeVerification?.paydayFrequency !== "" &&
    (employerDetails[index]?.incomeVerification?.paydayFrequency !== "DAILY"
      ? employerDetails[index]?.incomeVerification?.dayPaid && employerDetails[index]?.incomeVerification?.dayPaid !== ""
      : true);

  console.log("Employer Details", employerDetails, !validateEmployerDetails);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleEditButton = async (isAuthenticated: boolean) => {
    if (isAuthenticated || !employerDetails[index].verified) {
      setScreenLoader(true);
      // eslint-disable-next-line no-console
      console.log("Inside HandleEdit", employerDetails, updatedEmployerDetails, incomeDoc);
      const updatedDetails: any = [];
      let decisionEngineId: any = '';
      for (let i = 0; i < employerDetails.length; i++) {
        const element = employerDetails[i];
        decisionEngineId = element?.decisionEngineId
        const verified = employerDetails.some((element: any) => element.verified == true);
        if (i == index) {
          updatedDetails.push({
            modifiedBy: index == 0 ? currentEmpId : undefined,
            verified,
            employerReferenceId: element.employerDetailsId,
            employerName: element.basicInfo.companyName,
            takeHomePay: element.incomeVerification.takeHomePay.replace(',', ''),
            lengthOfEmploymentYears: element.basicInfo.employmentYears,
            employerPhoneNumber: element.basicInfo.phoneNumber?.replace(/\D/g, ''),
            verifyCode: element.verifyCode,
            doNotCall: element.basicInfo.doNotCallEnabled
              ? STRING_YES
              : STRING_NO,
            employerPayschedule:
              element.incomeVerification.paydayFrequency == ""
                ? "0"
                : element.incomeVerification.paydayFrequency,
            employerPayscheduleDesc: element.incomeVerification.payDayFreqDesc,
            daysPaid:
              element.incomeVerification.dayPaid == ""
                ? "0"
                : element.incomeVerification.dayPaid,
            daysPaidDesc: element.incomeVerification.daysPaidDesc,
            decisionEngineId: element?.decisionEngineId,
            verifiedDate: element.verifiedDate
              ? moment(element.verifiedDate).format(DATE_FORMAT)
              : undefined,
            employmentBeginDate: element.basicInfo.employmentYears
              ? moment()
                .subtract(Number(element.basicInfo.employmentYears), "years")
                .format(DATE_FORMAT)
              : moment().format(DATE_FORMAT),
            incomeSourceType: element.incomeVerification.incomeSource
          });
        }
      }
      let uploadDocPayload: any = ``;
      if (incomeDocCopy) {
        uploadDocPayload = {
          isVerificationDocument: true,
          customerApprovalId: decisionEngineId,
          documents: [
            {
              documentType: 'EMPDOC',
              file: incomeDocCopy
            }
          ]
        }
      } else if (!incomeDocCopy && isIncomeDocDeleted) {
        uploadDocPayload = {
          action: 5,
          documentId: incomeDocId,
          isVerificationDocument: true,
          customerApprovalId: decisionEngineId,
        }
      }
      const updateEmpDetails: any = await updateCustomerAndVerification(
        updatedDetails,
        "EMP",
        customerId || "",
        setGetCustomerInfoResponse,
        setGetApprovalRes,
        '',
        '',
        incomeDocCopy == incomeDoc ? '' : uploadDocPayload,
        configurations?.EnableCustomerVerificationPhaseTwo,
        history
      );
      // eslint-disable-next-line no-console
      console.log("UpdateEmpDetails", updateEmpDetails);
      if (updateEmpDetails) {
        setIsIncomeDocDeleted(false);
        if (updateEmpDetails && updateEmpDetails?.status == 200 && updateEmpDetails?.data) {
          if (updateEmpDetails?.data?.documents[0]?.documentId) {
            setIncomeDocId(updateEmpDetails?.data?.documents[0]?.documentId);
          }
          setIncomeDoc(incomeDocCopy);
          setVerificationDocuments((prevArray: any) =>
            prevArray.map((item: any, index: any) => {
              if (item?.refCode === 'EMPDOC') {
                return { ...item, file: incomeDocCopy };
              }
              return item;
            })
          );
        }
        if (employerDetails[index].verified) {
          const empDetails = [
            ...employerDetails.slice(0, index),
            {
              ...employerDetails[index],
              isEditEnabled: true,
              verified: true,
              verifiedBy: currentEmpId,
              verifiedSource: "RACPad",
              verifiedOn: moment().format("MM/DD,YYYY"),
              isCancellable: false
            },
            ...employerDetails.slice(index + 1),
          ];
          setEmployerDetails(empDetails);
          setUpdatedEmployerDetails(empDetails);
          setIncomeDoc(incomeDocCopy);
        } else {
          console.log("Inside Elseif employerDetails", employerDetails, updatedEmployerDetails);
          const empDetails = [
            ...employerDetails.slice(0, index),
            {
              ...employerDetails[index],
              isEditEnabled: true,
              verified: false,
              isCancellable: false
            },
            ...employerDetails.slice(index + 1),
          ];
          console.log("After Log", empDetails);
          setEmployerDetails(empDetails);
          setUpdatedEmployerDetails(empDetails);
          setIsIncomeDocDeleted(false);
        }
      }
      setScreenLoader(false);
      setShowAuthentication(false);
    } else if (!isAuthenticated) {
      setShowAuthentication(true);
    }
  };

  const handleCancel = () => {
    console.log('HandleCancel', employerDetails, updatedEmployerDetails);
    setincomeDocCopy(incomeDoc);
    if (employerDetails[index].isCancellable) {
      console.log('If');
      setEmployerDetails((prevDetails: EmployerDetails[]) =>
        prevDetails.filter((_: any, i: any) => i !== index)
      );
      setUpdatedEmployerDetails((prevDetails: EmployerDetails[]) =>
        prevDetails.filter((_, i) => i !== index)
      );
    } else {
      const updateEdit = {
        isEditEnabled: true,
      };
      setUpdatedEmployerDetails((prevDetails: any) => {
        const newDetails = prevDetails.map((detail: any, i: number) =>
          i === index ? { ...detail, ...updateEdit } : detail
        );

        setEmployerDetails((prevDetails: any) =>
          prevDetails.map((detail: any, i: number) =>
            i === index ? { ...newDetails[index] } : detail
          )
        );

        return newDetails;
      });
      setEmployerDetailsErrorMessage((prevErrors: any) =>
        prevErrors.map((error: any, i: number) =>
          i === index
            ? {
              ...error,
              basicInfo: {
                companyName: EMPTY_STRING,
                employmentYears: EMPTY_STRING,
                phoneNumber: EMPTY_STRING,
                doNotCallEnabled: error.basicInfo.doNotCallEnabled,
                isEdited: error.basicInfo.isEdited,
              },
              incomeVerification: {
                paydayFrequency: EMPTY_STRING,
                dayPaid: EMPTY_STRING,
                takeHomePay: EMPTY_STRING,
                isEdited: error.incomeVerification.isEdited,
                payDayFreqDesc: EMPTY_STRING,
                daysPaidDesc: EMPTY_STRING,
              },
              addressInfo: {
                addressLine1: EMPTY_STRING,
                addressLine2: EMPTY_STRING,
                zip: EMPTY_STRING,
                city: EMPTY_STRING,
                state: EMPTY_STRING,
                doNotVisit: error.addressInfo.doNotVisit,
                isEdited: error.addressInfo.isEdited,
              },
              verified: EMPTY_STRING,
              active: error.active,
              verifiedDate: EMPTY_STRING,
              employerDetailsId: null,
              overrideEnabled: error.overrideEnabled,
              isEditEnabled: error.isEditEnabled,
              verifiedBy: EMPTY_STRING,
              decisionEngineId: EMPTY_STRING,
              verifiedSource: EMPTY_STRING,
              verifyCode: EMPTY_STRING,
            }
            : error
        )
      );
    }
  }

  const updateEditButton = () => {
    const updateEdit = {
      isEditEnabled: false,
    };
    const employerEdit = {
      isEditEnabled: false,
    };

    console.log('Before updatedEmployerDetails', updatedEmployerDetails);
    console.log('Before employerDetails', employerDetails);

    setUpdatedEmployerDetails((prev: any) => prev.map((empData: any, i: number) => i == index ? { ...empData, ...updateEdit } : empData));
    setEmployerDetails((prev: any) => prev.map((empData: any, i: number) => i == index ? { ...empData, ...employerEdit } : empData));
  };

  const handleSaveButtonValidation = () => {
    // incomeDocCopy ? handleEditButton(false) : checkMandatoryFields(index) ? handleEditButton(false) : null;
    if (incomeDocCopy) {
      handleEditButton(false)
      const errorMessageDetails = {
        basicInfo: {
          companyName: EMPTY_STRING,
          employmentYears: EMPTY_STRING,
          phoneNumber: EMPTY_STRING,
          doNotCallEnabled: false,
          isEdited: false,
        },
        incomeVerification: {
          paydayFrequency: EMPTY_STRING,
          dayPaid: EMPTY_STRING,
          takeHomePay: EMPTY_STRING,
          isEdited: false,
          payDayFreqDesc: EMPTY_STRING,
          daysPaidDesc: EMPTY_STRING,
          incomeSource: EMPTY_STRING
        },
        addressInfo: {
          addressLine1: EMPTY_STRING,
          addressLine2: EMPTY_STRING,
          zip: EMPTY_STRING,
          city: EMPTY_STRING,
          state: EMPTY_STRING,
          doNotVisit: false,
          isEdited: false,
        },
        verified: false,
        active: true,
        verifiedDate: EMPTY_STRING,
        employerDetailsId: null,
        overrideEnabled: false,
        isEditEnabled: false,
        verifiedBy: EMPTY_STRING,
        decisionEngineId: EMPTY_STRING,
        verifiedSource: EMPTY_STRING,
        verifyCode: EMPTY_STRING,
      };
      setErrorMessage([errorMessageDetails])
    } else if (!incomeDocCopy && isIncomeDocDeleted) {
      handleEditButton(false)
    } else if (!incomeDocCopy && checkMandatoryFields(index)) {
      handleEditButton(false)
    }

  }


  return (
    <Grid container md={12} className={`${classes.mt10px}`}>
      <Grid item md={10}>
        {updatedEmployerDetails[index].verified ? (
          <DynamicVerifiedStatus
            value={{
              verifiedBy: value.verifiedBy,
              verifiedSource: value.verifiedSource,
              verifiedOn: value.verifiedDate,
            }}
          />
        ) : null}
      </Grid>
      <Grid item md={2} className={`${classes.alignRight}`}>
        {!value.isEditEnabled ? (
          <RACButton
            variant="outlined"
            color="primary"
            onClick={() => {
              handleCancel();
            }}
          >
            Cancel
          </RACButton>
        ) : null}
        <RACButton
          variant="contained"
          color="primary"
          className={`${classes.ml10px} ${classes.alignRight}`}
          disabled={
            !value.isEditEnabled
              ? !(
                !(
                  JSON.stringify(actualDetails) ===
                  JSON.stringify(updatedEmpDetails)
                ) || !(incomeDoc == incomeDocCopy)
              )
              : false
          }
          onClick={async () => {
            if (value.isEditEnabled) {
              updateEditButton();
            } else {
              handleSaveButtonValidation();
            }
          }}
        >
          {`${!value.isEditEnabled ? "Save" : "Edit"}`}
        </RACButton>
      </Grid>
      {showAuthentication ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setShowAuthentication(false);
          }}
          setTwoFactorCompleted={(e: any) => handleEditButton(e)}
          moduleName={MODULE_NAME.EMPLOYMENT_VERIFICATION}
          currentRole={currentRole}
        />
      ) : null}
      {errorPopup && (
        <DynamicAlertPopup
          alertMessage="Something went wrong while uploading the document. Please try uploading a different document."
          alertType="ERROR"
          closeIcon={true}
          onCloseFn={() => {
            setErrorPopup(false);
          }}
        ></DynamicAlertPopup>
      )}
    </Grid>
  );
}