/* eslint-disable */
import {
  Card,
  CircularProgress,
  Grid,
  RACButton,
  Typography,
} from "@rentacenter/racstrap";
import { ReactComponent as ExpandAlert } from "../../../assets/images/expand-alert-icon.svg";
import { ReactComponent as CancelAlert } from "../../../assets/images/alert-cancel-icon.svg";
import { updateCustomerStyles } from "../../../jsStyles/updateCustomerStyles";
import React, { useContext, useEffect } from "react";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { useParams } from "react-router-dom";
import { UpdateAlert } from "../../../api/user";
import { getCustomerAlerts } from "../../../apiHandler/updateCustomerApiHandler";
import { AssignAlerts } from "./AssignAlerts";
import { Alert, CustomerAlertsResponse, removeAlert } from "../../../interface/updateCustomerInterface";
import { VerificationNotrequired } from "../../sharedComponents/VerificationNotRequired";

export function CustomerAlerts() {
  const classes = updateCustomerStyles();
  let { Agrtransferinit, setAlertIsOpen, assignedAlerts, setAssignedAlerts,
    isAlertLoading, setIsAlertLoading, activeAlert, setActiveAlert,
    setactivetab, setAlertUpdateObj, AlertLoad, setAlertLoad, verificationNotrequiredEnable, setVerificationNotrequiredEnable, verificationNotrequiredPayload, notRequiredprovided, setnotRequiredprovided, getApprovalRes, setVerificationNotrequiredPayload
  } = useContext(UpdateCustomerContext);
  const { customerId } = useParams<{ customerId: string }>();

  useEffect(() => {
    buildActiveAlerts()
  }, [assignedAlerts])

  const removeAlert = async (removeAlert: removeAlert[]) => {
    try {
      const payload = {
        alertTypes: removeAlert,
        customerId: parseInt(customerId),
        userId: '191197',
      };

      setIsAlertLoading(true);

      const response = await UpdateAlert(payload, customerId);

      if (response && response.status == 200) {
        const getCustomerAlert = await getCustomerAlerts(customerId);
        setAssignedAlerts(getCustomerAlert);
        buildActiveAlerts(getCustomerAlert);
      }
      setIsAlertLoading(false);
    } catch (e: any) {
      console.log('error while removing alert');
      setIsAlertLoading(false);
    }
  };

  function openAlert() {
    setAlertIsOpen(true);
  }

  const buildActiveAlerts = (optionalAlerts?: CustomerAlertsResponse[]) => {
    let customerAlerts = assignedAlerts;
    if (optionalAlerts) {
      customerAlerts = optionalAlerts;
    }
    console.log(customerAlerts, 'assignedAlerts in build active')
    const activeAlerts: CustomerAlertsResponse[] = customerAlerts?.length ?
      customerAlerts.filter((val: CustomerAlertsResponse) => val.alertCleared == 0) : [];
    const popupAlerts: CustomerAlertsResponse[] = customerAlerts?.length ?
      customerAlerts.filter((val: CustomerAlertsResponse) =>
        val.alertCleared == 0 &&
        val.alertTypeId !== '3' &&
        val.alertTypeId !== 3
      ) : [];

    const alertLoad: Alert[] = customerAlerts?.map((val: CustomerAlertsResponse) => ({
      alertTypeId: val.alertTypeId,
      alertCleared: val.alertCleared,
    })) || [];

    setAlertLoad(alertLoad);
    setActiveAlert(activeAlerts);
    setAlertUpdateObj([...popupAlerts]);
  };


  const verificationNotRequiredCheckFunction = async (tab?: any) => {
    if (tab == 2) {

      const residenceInfoDocument: any = getApprovalRes?.verificationDocuments &&
        getApprovalRes?.verificationDocuments?.find(
          (element: any) => element.verifyType == "RESIDENCE"
        )
      if (residenceInfoDocument && residenceInfoDocument?.verifyRequired == 'N' && !notRequiredprovided.includes(tab)) {
        setVerificationNotrequiredEnable(true)
        verificationNotrequiredPayload = { verificationNotrequiredPayload, activetabType: tab, verifyType: residenceInfoDocument?.verifyType, verificationDocument: residenceInfoDocument }
        setVerificationNotrequiredPayload(verificationNotrequiredPayload)
      }
      else {
        setactivetab(tab)

      }
    }
    if (tab == 3) {

      const empInfoDocument: any = getApprovalRes?.verificationDocuments &&
        getApprovalRes?.verificationDocuments?.find(
          (element: any) => element.verifyType == "EMPLOYMENT"
        )
      if (empInfoDocument && empInfoDocument?.verifyRequired == 'N'  && !notRequiredprovided.includes(tab)) {
        setVerificationNotrequiredEnable(true)
        verificationNotrequiredPayload = { verificationNotrequiredPayload, activetabType: tab, verifyType: empInfoDocument?.verifyType, verificationDocument: empInfoDocument }
        setVerificationNotrequiredPayload(verificationNotrequiredPayload)
      }
      else {
        setactivetab(tab)
      }
    }
    else (
      setactivetab(tab)
    )

  }

  const ScrollToPage = async (Id: number | string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (e !== null) {
      e.preventDefault();
    }
    const elementId: string =
      Id == '6'
        ? 'addressDiv'
        : Id == '9'
          ? 'ParentContactInformation'
          : Id == '4' || Id == '2' || Id == '5'
            ? 'LegalDiv'
            : '';

    const Tab: number | string = Id == '7' ? 3 : Id == '11' ? 2 : '';
    if (elementId !== '') {
      const titleElement = document.getElementById(elementId);
      if (titleElement !== null) {
        titleElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (Tab !== '') {
      await verificationNotRequiredCheckFunction(Tab)
    }
  }

  const AlertMessages = () => {
    console.log(activeAlert, 'activeAlert in assign alert')
    if (activeAlert) {
      return activeAlert.map((val: CustomerAlertsResponse, index: number) => {
        const alertActive = AlertLoad.findIndex(
          (alert: Alert) =>
            alert.alertTypeId == val.alertTypeId && alert.alertCleared == 0
        );
        if (alertActive !== -1) {
          return (
            <span className={`${classes.alertWidgetStyle} ${classes.me2}`} key={index}>
              {
                val.alertTypeId !== '3' && val.alertTypeId !== 3 ? (
                  <a
                    onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => ScrollToPage(val.alertTypeId, e)}
                    className={`${classes.me2} ${classes.customerPointercursor}`}
                  >
                    <ExpandAlert></ExpandAlert>
                  </a>
                ) : null
              }
              {val.alertTypeDescEn == 'Other' ? val.alertText : val.alertTypeDescEn}
              <a className={`${classes.ms2} ${classes.customerPointercursor}`}>
                <CancelAlert
                  onClick={() => removeAlert(
                    [
                      {
                        alertTypeId: parseInt(val.alertTypeId),
                        customerAlertId: parseInt(val.customerAlertId),
                        alertText: '',
                        alertClear: 1,
                      },
                    ]
                  )
                  }
                ></CancelAlert>
              </a>
            </span>
          );
        }
      });
    }
  };
  const verificationNotRequiredCompletedFn = async (type?: any) => {
    setVerificationNotrequiredEnable(false);
    setnotRequiredprovided((prevArray: any) => [...prevArray, type]);
    setactivetab(type);
  };

  const verificationNotRequiredCancelledFn = async () => {
    setVerificationNotrequiredEnable(false);
  };

  return (
    <>

      {verificationNotrequiredEnable ? (
        <VerificationNotrequired
          customerId={String(customerId)}
          activetabType={verificationNotrequiredPayload.activetabType}
          documentType={verificationNotrequiredPayload.verifyType}
          verificationNotRequiredCancelledFn={() => verificationNotRequiredCancelledFn()}
          verificationNotRequiredCompletedFn={(e: any) => verificationNotRequiredCompletedFn(e)}
        />) : null}
      <Card className={`${classes.card} ${classes.w100} ${classes.py2} ${classes.mx2}`}>
        <Grid item className={`${classes.racCol12} ${classes.floatLeft}`}>
          <Grid item className={`${classes.racCol10} ${classes.floatLeft} ${classes.ps2}`}>
            <Grid item className={`${classes.w100} ${classes.floatLeft} ${classes.mt2}`}>
              <React.Fragment>
                <Typography
                  variant="h4"
                  className={`${classes.font16} ${classes.bold} ${classes.floatLeft} ${classes.me1} ${classes.mt1}`}
                >
                  Customer Alert
                </Typography>
                <Typography
                  variant="h4"
                  className={`${classes.font16} ${classes.bold} ${classes.floatLeft} ${classes.me4} ${classes.mt1}`}
                >
                  {`(${activeAlert.length})` || ''}
                </Typography>
              </React.Fragment>
              <Grid item className={classes.alertWidgetContainer}>
                {AlertMessages()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={`${classes.racCol2} ${classes.floatLeft} ${classes.ps2}`}>
            <Grid container>
              <Grid item className={`${classes.racCol2} ${classes.spacerMR2}`}>
                {isAlertLoading ? (
                  <Grid item className={`${classes.customerNorecordsAlert} ${classes.ms2}`}>
                    <CircularProgress
                      className={classes.alertLoader}
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Grid item className={classes.racCol8}>
                <RACButton
                  variant="contained"
                  color="primary"
                  id="assignAlertBtn"
                  disabled={Agrtransferinit}
                  className={classes.mt1}
                  onClick={openAlert}
                >
                  Assign Alert
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <AssignAlerts />
    </>
  );
}
